<template>
  <v-card outlined class="my-2">
    <v-card-text>
      <h5 class="mb-4">{{ affiliation.nomOrganisation }}</h5>

      <div v-if="modeEdition">
        <v-form ref="form" v-model="formValide">
          <v-autocomplete
            :label="$t('affiliation.organization')"
            :items="organisations"
            item-text="nom"
            item-value="clef"
            v-model="choixOrganisation"
            @change="onChoixOrganisation"
            :rules="[v => !!v || $t('champ_obligatoire')]"
          ></v-autocomplete>
          <div>
            <p v-html="infoOrganisation"></p>
          </div>

          <v-select
            :label="$t('affiliation.job-title')"
            outlined
            v-model="editionAffiliation.titreEmploi"
            :items="listeTitresEmploi"
            item-text="texte"
            item-value="clef"
            :rules="[v => !!v || $t('champ_obligatoire')]"
          ></v-select>

          <!-- C'est quand même un peu lourd... pour un simple DatePicker -->

          <v-menu v-model="dtDebutOuvert" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="editionAffiliation.dateDebut"
                :label="$t('affiliation.employment-beginning')"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="editionAffiliation.dateDebut" @input="dtDebutOuvert = false"></v-date-picker>
          </v-menu>

          <v-menu v-model="dtFinOuvert" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="editionAffiliation.dateFin"
                :label="$t('affiliation.employment-ending')"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="editionAffiliation.dateFin" @input="dtFinOuvert = false"></v-date-picker>
          </v-menu>

          <v-select
            :label="$t('affiliation.employment-relationship')"
            outlined
            v-model="editionAffiliation.lienEmploi"
            :items="listeLienEmploi"
            item-text="texte"
            item-value="clef"
            :rules="[v => !!v || $t('champ_obligatoire')]"
          ></v-select>


          <!--     Retiré sur demande de Lisa, ils utiliseront seulement la valeur dans le dossier membre     -->
          <!--          (todo: convertir minutes)-->
          <!--          <v-text-field-->
          <!--            :label="$t('affiliation.average-hours-worked')"-->
          <!--            v-model="editionAffiliation.minutesTravailSemaine"-->
          <!--            :rules="[valeur => !!valeur || $t('champ_obligatoire')]"-->
          <!--          ></v-text-field>-->
          <!--          (todo: forcer nombre)-->
          <!--          <v-text-field-->
          <!--            :label="$t('affiliation.number-of-days')"-->
          <!--            v-model="editionAffiliation.nombreJours"-->
          <!--            :rules="[valeur => !!valeur || $t('champ_obligatoire')]"-->
          <!--          ></v-text-field>-->

          <v-checkbox :label="$t('affiliation.main-practice-location')" v-model="editionAffiliation.emploiPrincipal"></v-checkbox>
        </v-form>
        <v-btn class="primary" @click="onSauvegarder()">{{$t('gui.general.sauvegarder')}}</v-btn>
        <v-btn @click="onCancel">{{$t('gui.general.annuler')}}</v-btn>
      </div>

      <div v-if="!modeEdition">
        <div>
          <p v-html="affiliation.coordonnees"></p>
        </div>

        <v-btn @click="voirTout=true" v-if="!voirTout">{{$t('affiliation.read-more')}}</v-btn>

        <div v-if="voirTout">
          <b>{{ $t('affiliation.job-title') }}</b>
          <p>{{ affiliation.titreEmploi }}</p>

          <b>{{ $t('affiliation.employment-beginning') }}</b>
          <p>{{ affiliation.dateDebut }}</p>

          <b>{{ $t('affiliation.employment-ending') }}</b>
          <p>{{ affiliation.dateFin }}</p>

          <b>{{ $t('affiliation.employment-relationship') }}</b>
          <p>{{ affiliation.lienEmploi }}</p>

          <!--     Retiré sur demande de Lisa, ils utiliseront seulement la valeur dans le dossier membre     -->
          <!--          <b>{{ $t('affiliation.average-hours-worked') }}</b>-->
          <!--          <p>{{ affiliation.minutesTravailSemaine/60 }}</p>-->

          <!--          <b>{{ $t('affiliation.number-of-days') }}</b>-->
          <!--          <p>{{ affiliation.nombreJours }}</p>-->
        </div>

        <v-btn @click="voirTout=false" v-if="voirTout">{{$t('affiliation.read-less')}}</v-btn>
        <v-btn @click="onActiverEdition" class="mx-4">
          <v-icon left>mdi-pencil</v-icon>{{$t('gui.general.edit')}}
        </v-btn>


        <v-dialog v-model="dialogSuppression" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on">
              <v-icon left>mdi-trash-can</v-icon>{{$t('gui.general.delete')}}
            </v-btn>
          </template>

          <v-card>
            <v-card-title>{{$t('gui.general.confirmation')}}</v-card-title>
            <v-card-text>
              <p>{{$t('affiliation.delete-confirmation')}}</p>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="dialogSuppression = false">{{$t('gui.general.annuler')}}</v-btn>
              <v-btn @click="onSupprimer" class="primary">{{$t('gui.general.delete')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import RestApiService from "@/services/rest.api.service.js";

export default {
  props: {
    affiliation: Object,
  },
  data: function () {
    return {
      formValide: false,
      menuDate: false,
      voirTout: false,
      modeEdition: false,
      organisations: {},
      choixOrganisation: null,
      infoOrganisation: null,
      editionAffiliation: null,
      listeLienEmploi: null,
      listeTitresEmploi: null,
      dialogSuppression: false,
      dtDebutOuvert: false,
      dtFinOuvert: false,
    };
  },
  created() {
    // RestApiService.setTokenHeader(this.$token);
    // RestApiService.setLang(this.$locale);
    // RestApiService.setServeur(this.$serveur);
    if (this.affiliation.nouveau) {
      this.onActiverEdition();
    }
  },
  methods: {
    onChoixOrganisation() {
      const url = `/resto/csia/joomla/organisation/coordonnees/${this.choixOrganisation}`;
      console.log("onChoixOrganisation " + url);
      RestApiService.get(url)
        .then((response) => {
          this.infoOrganisation = response.data.clef;
        })
        .catch((error) => window.alert(error));
    },
    onActiverEdition() {
      console.log("onActiverEdition");
      this.modeEdition = true;
      this.editionAffiliation = this.affiliation;
      if (this.editionAffiliation.dateDebut != null)
        this.editionAffiliation.dateDebut = this.affiliation.dateDebut.substring(
          0,
          10
        );
      if (this.editionAffiliation.dateFin != null)
        this.editionAffiliation.dateFin = this.affiliation.dateFin.substring(
          0,
          10
        );
      this.choixOrganisation = this.editionAffiliation.clefEntreprise;

      RestApiService.get("/resto/csia/joomla/organisation/nom")
        .then((response) => {
          this.organisations = response.data;
          this.organisations.sort((a, b) => a.nom.localeCompare(b.nom));
        })
        .catch((error) => window.alert(error));
      RestApiService.get("/resto/csia/joomla/usager/listeChoixMenu")
        .then((response) => {
          this.listeLienEmploi = response.data.listeLienEmploi;
          this.listeTitresEmploi = response.data.listeTitresEmploi;
        })
        .catch((error) => window.alert(error));
    },
    onSauvegarder() {
      this.$refs.form.validate();
      if (this.formValide) {
        this.editionAffiliation.clefEntreprise = this.choixOrganisation;
        if (this.editionAffiliation.minutesTravailSemaine == null) {
          this.editionAffiliation.minutesTravailSemaine = 0;
        }

        if (this.editionAffiliation.nouveau) {
          // retirer le champ nouveau
          // enrique : faire les ajustements nécessaires....
          delete this.editionAffiliation['nouveau'];
          RestApiService.post(
            "/resto/csia/joomla/usager/nouvelleAffiliation",
            this.editionAffiliation
          )
          .then(() => {
            this.modeEdition = false;
          })
          .catch((error) => window.alert(error)).finally(() => {
            this.$emit("sauvegardeAffiliation");
          });
        } else {
          RestApiService.put(
            "/resto/csia/joomla/usager/modifAffiliation",
            this.editionAffiliation
          )
          .then(() => {
            this.modeEdition = false;
          })
          .catch((error) => window.alert(error)).finally(() => {
            this.$emit("sauvegardeAffiliation");
          });
        }
      }
    },

    async onSupprimer() {
      this.dialogSuppression = false;

      await RestApiService.post(
        "/resto/csia/joomla/usager/supprimerAffiliation",
        this.affiliation
      )

      this.$emit("sauvegardeAffiliation")
    },

    onCancel() {
      this.modeEdition = false
      // forcer le rechargement des affiliations pour retirer l'affiliation vide
      if (this.affiliation.nouveau) this.$emit("sauvegardeAffiliation");
    }
  },
};
</script>