import { render, staticRenderFns } from "./Catalogue.vue?vue&type=template&id=5fdcc7d2&scoped=true&"
import script from "./Catalogue.vue?vue&type=script&lang=js&"
export * from "./Catalogue.vue?vue&type=script&lang=js&"
import style0 from "./Catalogue.vue?vue&type=style&index=0&id=5fdcc7d2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fdcc7d2",
  null
  
)

/* custom blocks */
import block0 from "./Catalogue.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VApp,VBtn,VCol,VCombobox,VDatePicker,VIcon,VMenu,VRow,VSpacer,VTextField})
