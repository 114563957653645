<template>
  <div>
    <v-card v-if="displaySession" style="padding: 15px 25px">
      <div class="d-flex align-items-center wrap-formation-nom">
        <h1 style="margin: 0">{{ seance.nom }}</h1>
        <div class="wrap-details">
          <Details :info="formation" />
        </div>
      </div>
      <div class="wrap-seance-details">
        <div>
          <strong>{{ $t("register.start") }}:</strong> {{ seance.dateDebut }}
        </div>
        <div>
          <strong>{{ $t("register.fin") }}:</strong> {{ seance.dateFin }}
        </div>
        <div v-if="seance.afficherPlace && seance.nombrePlaces">
          <strong>{{ $t("register.places") }}:</strong>
          {{ seance.nombrePlaces }}
        </div>
      </div>
      <div class="wrap-seance-details">
        <div>
          <strong>{{ $t("register.location") }}:</strong>
          {{ seance.emplacement }}
        </div>
        <div>
          <strong>{{ $t("register.langue") }}:</strong> {{ seance.langues }}
        </div>
        <div>
          <strong>{{ $t("gui.general.description") }}:</strong>
        </div>
        <div>{{ seance.description }}</div>
        <div>
          <strong>{{ $t("register.notesImportantes") }}:</strong>
        </div>
        <div>{{ seance.messageAvertissement }}</div>
      </div>
      <div v-if="!infoInscription.inscriptionPossible">
        <div v-html="infoInscription.htmlMessageUtilisateur"></div>
      </div>
      <div v-if="infoInscription.inscriptionPossible">
        <h3 class="mt-12" v-if="enfants.length > 0">
          {{ $t("csia.inscription.programmeEntier") }}
        </h3>
        <v-row>
          <v-col cols="12" sm="7">
            <h4>{{ seance.nom }}</h4>
            <div>
              <strong>{{ $t("register.regular_price") }}:</strong>
              {{ seance.prixRegulier }}
            </div>
            <div v-if="seance.prixHatif">
              <strong>{{ $t("register.early_price") }}:</strong>
              {{ seance.prixHatif }}
            </div>
          </v-col>
          <v-col class="text-right" cols="12" sm="5">
            <span
              v-if="
                seance.nombrePlaces &&
                seance.nombrePlaces > 0 &&
                !seance.inscriptionPossible &&
                !afficherEnfantComplet
              "
              >{{ $t("catalogue.prealables_non_remplis") }}</span
            >
            <p small v-if="afficherEnfantComplet">
              {{ $t("csia.catalogue.complet") }}
            </p>

            <v-btn
              v-else
              class="primary-custom"
              @click="addToCart(seance.idSeance)"
              :disabled="!seance.inscriptionPossible"
            >
              {{ $t("gui.general.add_to_cart") }}
            </v-btn>
            <p small v-if="!seance.inscriptionPossible && !afficherEnfantComplet">
              {{ seance.messageRestrictionUsager }}
            </p>
          </v-col>
        </v-row>

        <div v-if="enfants.length > 0">
          <h3 class="mt-12">{{ $t("csia.inscription.programmePartielle") }}</h3>

          <div class="wrap-sous-seance-list">
            <v-row v-for="enfant in enfants" :key="enfant.identifiant">
              <pre></pre>
              <v-row>
                <v-col cols="12" sm="8">
                  <h4>{{ enfant.nom }}</h4>
                  <div>
                    <strong>{{ $t("register.start") }}:</strong>
                    {{ enfant.dateDebut }}
                  </div>
                  <div>
                    <strong>{{ $t("register.fin") }}:</strong>
                    {{ enfant.dateFin }}
                  </div>

                  <div>
                    <strong>{{ $t("register.regular_price") }}:</strong>
                    {{ enfant.prixRegulier }}
                  </div>
                  <div v-if="enfant.prixHatif">
                    <strong>{{ $t("register.early_price") }}:</strong>
                    {{ enfant.prixHatif }}
                  </div>
                </v-col>
                <v-col class="text-right" cols="12" sm="4">
                  <v-btn
                    @click="addToCart(enfant.idSeance)"
                    class="primary-custom"
                    :disabled="!enfant.inscriptionPossible"
                    >{{ $t("gui.general.add_to_cart") }}
                  </v-btn>
                  <v-btn
                    v-if="enfant.complet"
                    @click="ajouterListeAttente(enfant)"
                    class="primary-custom"
                    >{{ $t("csia.catalogue.ajouterListeAttente") }}
                  </v-btn>
                  <p
                    small
                    v-if="!enfant.inscriptionPossible && !enfant.complet"
                  >
                    {{ enfant.messageRestrictionUsager }}
                  </p>
                  <p small v-if="enfant.complet">
                    {{ $t("csia.catalogue.complet") }}
                  </p>
                </v-col>
              </v-row>
            </v-row>
          </div>
        </div>
      </div>
      <v-row class="d-flex justify-center">
        <v-col cols="12" sm="8">
          <v-btn
            style="width: 100%"
            class="btn-wrap-text primary-custom"
            @click="$emit('returnToSearch')"
            >{{ $t("register.return_to_training_directory") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
    <Payment v-else @returnToSearch="$emit('returnToSearch')" />
  </div>
</template>

<script>
import Vue from "vue";
import VueI18n from "vue-i18n";
import i18n from "../../../i18n";
import vuetify from "../../../plugins/vuetify";
import RestApiService from "@/services/rest.api.service.js";
import "@mdi/font/css/materialdesignicons.css";
import Details from "../Details";
import Payment from "./Payment";

Vue.use(VueI18n);

export default {
  components: {
    Details,
    Payment,
  },
  props: {
    formation: Object,
    emplacement: Object,
    idSeance: Number,
  },
  data: function () {
    return {
      inscriptionPossible: true,
      nbrPlaces: 0,
      displaySession: true,
      enfants: [],
      seance: {},
      infoInscription: {},
      afficherEnfantComplet : false,
    };
  },
  created: function () {
    this.$i18n.locale = this.$locale;
    RestApiService.setTokenHeader(this.$token);
    RestApiService.setLang(this.$locale);
    RestApiService.setServeur(this.$serveur);
    window.scrollTo(0, 0);
  },
  mounted: function () {
    RestApiService.get(
      `/resto/csia/joomla/catalogue/infoInscription/${this.idSeance}`
    )
      .then((response) => {
        const data = response.data;
        this.infoInscription = response.data;
        this.inscriptionPossible = data.inscriptionPossible;
        this.nbrPlaces = data.nombrePlaces;
        this.seance = data.seance;
        this.enfants = data.enfants;
        if(this.enfants)
        {
          var index = 0;  
          for (index = 0; index < this.enfants.length; index++)
          {
            if(this.enfants[index].complet)
            {
              this.afficherEnfantComplet = true;
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
        window.alert(error);
      });
  },
  methods: {
    addToCart: function (id) {
      const body = {
        idSeance: id,
      };
      RestApiService.post(
        `/resto/csia/joomla/panierAchat/ajouter/seance`,
        body
      ).then(() => {
        this.displaySession = false;
      });
    },
    ajouterListeAttente (item){
      const info = {
        idSeance : item.idSeance
      }
      RestApiService.put('/resto/csia/joomla/catalogue/ajouterAttenteSeance',info)
        .then((r)=>alert(r.data))
    },
  },
  computed: {},
  vuetify,
  i18n,
};
</script>

<style scoped>
.wrap-formation-nom {
  margin-bottom: 15px;
}

.wrap-details {
  margin: 0 15px;
}

.wrap-seance-details {
  margin-bottom: 15px;
}

.wrap-sous-seance-list {
  margin: 25px 0px;
}

.wrap-unavailable {
  font-size: 1rem;
  text-align: center;
}

.btn-wrap-text {
  display: block;
  width: 100%;
  word-break: keep-all;
  text-align: center;
  white-space: normal;
  padding: 8px 16px !important;
  height: 100% !important;
  min-height: 36px;
}

@media (max-width: 767px) {
  .wrap-formation-nom {
    flex-direction: column;
  }
}
</style>