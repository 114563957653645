<template>
  <v-app>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      style="margin: auto; background-color:transparent; display: block; shape-rendering: auto;"
      width="200px"
      height="200px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle cx="75" cy="50" fill="#666666" r="5">
        <animate
          attributeName="r"
          values="3;3;5;3;3"
          times="0;0.1;0.2;0.3;1"
          dur="1s"
          repeatCount="indefinite"
          begin="-0.9166666666666666s"
        />
        <animate
          attributeName="fill"
          values="#666666;#666666;#353535;#666666;#666666"
          repeatCount="indefinite"
          times="0;0.1;0.2;0.3;1"
          dur="1s"
          begin="-0.9166666666666666s"
        />
      </circle>
      <circle cx="71.65063509461098" cy="62.5" fill="#666666" r="5">
        <animate
          attributeName="r"
          values="3;3;5;3;3"
          times="0;0.1;0.2;0.3;1"
          dur="1s"
          repeatCount="indefinite"
          begin="-0.8333333333333334s"
        />
        <animate
          attributeName="fill"
          values="#666666;#666666;#353535;#666666;#666666"
          repeatCount="indefinite"
          times="0;0.1;0.2;0.3;1"
          dur="1s"
          begin="-0.8333333333333334s"
        />
      </circle>
      <circle cx="62.5" cy="71.65063509461096" fill="#666666" r="5">
        <animate
          attributeName="r"
          values="3;3;5;3;3"
          times="0;0.1;0.2;0.3;1"
          dur="1s"
          repeatCount="indefinite"
          begin="-0.75s"
        />
        <animate
          attributeName="fill"
          values="#666666;#666666;#353535;#666666;#666666"
          repeatCount="indefinite"
          times="0;0.1;0.2;0.3;1"
          dur="1s"
          begin="-0.75s"
        />
      </circle>
      <circle cx="50" cy="75" fill="#666666" r="5">
        <animate
          attributeName="r"
          values="3;3;5;3;3"
          times="0;0.1;0.2;0.3;1"
          dur="1s"
          repeatCount="indefinite"
          begin="-0.6666666666666666s"
        />
        <animate
          attributeName="fill"
          values="#666666;#666666;#353535;#666666;#666666"
          repeatCount="indefinite"
          times="0;0.1;0.2;0.3;1"
          dur="1s"
          begin="-0.6666666666666666s"
        />
      </circle>
      <circle cx="37.50000000000001" cy="71.65063509461098" fill="#666666" r="5">
        <animate
          attributeName="r"
          values="3;3;5;3;3"
          times="0;0.1;0.2;0.3;1"
          dur="1s"
          repeatCount="indefinite"
          begin="-0.5833333333333334s"
        />
        <animate
          attributeName="fill"
          values="#666666;#666666;#353535;#666666;#666666"
          repeatCount="indefinite"
          times="0;0.1;0.2;0.3;1"
          dur="1s"
          begin="-0.5833333333333334s"
        />
      </circle>
      <circle cx="28.34936490538903" cy="62.5" fill="#666666" r="5">
        <animate
          attributeName="r"
          values="3;3;5;3;3"
          times="0;0.1;0.2;0.3;1"
          dur="1s"
          repeatCount="indefinite"
          begin="-0.5s"
        />
        <animate
          attributeName="fill"
          values="#666666;#666666;#353535;#666666;#666666"
          repeatCount="indefinite"
          times="0;0.1;0.2;0.3;1"
          dur="1s"
          begin="-0.5s"
        />
      </circle>
      <circle cx="25" cy="50" fill="#666666" r="5">
        <animate
          attributeName="r"
          values="3;3;5;3;3"
          times="0;0.1;0.2;0.3;1"
          dur="1s"
          repeatCount="indefinite"
          begin="-0.4166666666666667s"
        />
        <animate
          attributeName="fill"
          values="#666666;#666666;#353535;#666666;#666666"
          repeatCount="indefinite"
          times="0;0.1;0.2;0.3;1"
          dur="1s"
          begin="-0.4166666666666667s"
        />
      </circle>
      <circle cx="28.34936490538903" cy="37.50000000000001" fill="#666666" r="5">
        <animate
          attributeName="r"
          values="3;3;5;3;3"
          times="0;0.1;0.2;0.3;1"
          dur="1s"
          repeatCount="indefinite"
          begin="-0.3333333333333333s"
        />
        <animate
          attributeName="fill"
          values="#666666;#666666;#353535;#666666;#666666"
          repeatCount="indefinite"
          times="0;0.1;0.2;0.3;1"
          dur="1s"
          begin="-0.3333333333333333s"
        />
      </circle>
      <circle cx="37.499999999999986" cy="28.349364905389038" fill="#666666" r="5">
        <animate
          attributeName="r"
          values="3;3;5;3;3"
          times="0;0.1;0.2;0.3;1"
          dur="1s"
          repeatCount="indefinite"
          begin="-0.25s"
        />
        <animate
          attributeName="fill"
          values="#666666;#666666;#353535;#666666;#666666"
          repeatCount="indefinite"
          times="0;0.1;0.2;0.3;1"
          dur="1s"
          begin="-0.25s"
        />
      </circle>
      <circle cx="49.99999999999999" cy="25" fill="#666666" r="5">
        <animate
          attributeName="r"
          values="3;3;5;3;3"
          times="0;0.1;0.2;0.3;1"
          dur="1s"
          repeatCount="indefinite"
          begin="-0.16666666666666666s"
        />
        <animate
          attributeName="fill"
          values="#666666;#666666;#353535;#666666;#666666"
          repeatCount="indefinite"
          times="0;0.1;0.2;0.3;1"
          dur="1s"
          begin="-0.16666666666666666s"
        />
      </circle>
      <circle cx="62.5" cy="28.349364905389034" fill="#666666" r="5">
        <animate
          attributeName="r"
          values="3;3;5;3;3"
          times="0;0.1;0.2;0.3;1"
          dur="1s"
          repeatCount="indefinite"
          begin="-0.08333333333333333s"
        />
        <animate
          attributeName="fill"
          values="#666666;#666666;#353535;#666666;#666666"
          repeatCount="indefinite"
          times="0;0.1;0.2;0.3;1"
          dur="1s"
          begin="-0.08333333333333333s"
        />
      </circle>
      <circle cx="71.65063509461096" cy="37.499999999999986" fill="#666666" r="5">
        <animate
          attributeName="r"
          values="3;3;5;3;3"
          times="0;0.1;0.2;0.3;1"
          dur="1s"
          repeatCount="indefinite"
          begin="0s"
        />
        <animate
          attributeName="fill"
          values="#666666;#666666;#353535;#666666;#666666"
          repeatCount="indefinite"
          times="0;0.1;0.2;0.3;1"
          dur="1s"
          begin="0s"
        />
      </circle>
      <!-- [ldio] generated by https://loading.io/ -->
    </svg>
  </v-app>
</template>

<script>
import vuetify from "../plugins/vuetify";

export default {
  data: function () {
    return {};
  },
  created: function () {},
  mounted: function () {
    //RestApiService.get("")
  },
  methods: {},
  computed: {},
  vuetify,
};
</script>

<style scoped>
</style>