<template>
  <div>
    <v-layout>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title class="headline">
              <v-avatar left size="64px">
                <v-img :src="info.urlImage"></v-img>
              </v-avatar>
              <div class="px-3">
                <h2>{{ info.nom }}</h2>
                <v-dialog v-model="dialog" max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary-custom" small v-bind="attrs" v-on="on">{{ $t("gui.general.details.simple")
                      }}</v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{
                        $t("csia.repertoire.detailSeance")
                        }}</span>
                    </v-card-title>

                    <v-card-text>
                      <h5 v-if="info.description !== ''">
                        {{ $t("gui.general.description") }}
                      </h5>
                      <div v-html="info.description" />
                      <h5 v-if="info.buts !== ''">
                        {{ $t("csia.repertoire.butFormation") }}
                      </h5>
                      <div v-html="info.buts" />
                      <h5 v-if="info.préalables !== ''">
                        {{ $t("csia.repertoire.prealables") }}
                      </h5>
                      <div v-html="info.préalables" />
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="dialog = false">{{ $t("gui.general.fermer") }}</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </v-card-title>
            <div v-for="emplacement in info.listeEmplacements" :key="emplacement.id">
              <v-card-subtitle>
                <h3 class="px-3">
                  {{ $t("emplacement.nom") }} {{ emplacement.nom }}
                </h3>
              </v-card-subtitle>
              <v-card-text>
                <v-data-table :no-data-text="$t('gui.general.no_results')"
                  :no-results-text="$t('gui.general.no_results')" :headers="headers" :items="emplacement.listeSéances"
                  :items-per-page="999" hide-default-footer>
                  <template v-slot:item.information="{ item }">
                    <div v-if="item.description">
                      <v-dialog v-model="item.dialogDescriotion" max-width="390">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn small color="primary-custom" v-bind="attrs" v-on="on">
                            <v-icon>mdi-information-variant</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="text-h5">
                            {{ $t('gui.general.description') }}
                          </v-card-title>
                          <v-card-text>
                            {{ item.description }}</v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1" text @click="item.dialogDescriotion = false">
                              Ok
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <!-- <v-row no-gutters align="center">
                      <v-col class="text-left"> -->

                    <!-- </v-col>
                      <v-col class="text-right"> -->
                    <div v-if="!item.complet && !item.annulé">
                      <v-btn @click="register(item, info, emplacement)" color="primary-custom">
                        <v-icon left>mdi-account-plus</v-icon>
                        <span>{{ $t("gui.general.register") }}</span>
                      </v-btn>
                    </div>
                    <div v-if="item.complet">
                      {{ $t('csia.catalogue.complet') }}
                      <v-btn @click="ajouterListeAttente(item)" color="primary-custom" v-if="$token">
                        <v-icon left>mdi-account-plus</v-icon>
                        <span>{{ $t('csia.catalogue.ajouterListeAttente') }}</span>
                      </v-btn>
                    </div>
                    <div v-if="item.annulé">
                      {{ $t('csia.catalogue.session-cancelled') }}
                    </div>
                    <!-- </v-col>
                    </v-row> -->
                  </template>
                </v-data-table>
              </v-card-text>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-layout>
  </div>
</template>
<i18n>
{
  "fr":{
    "gui.general.details.simple" : "Détails",
    "emplacement.nom" :"Emplacement",
    "gui.general.dates.dateDebut" : "Date de début",
    "gui.general.dates.dateFin" : "Date de fin",
    "gui.general.langue" : "Langue",
    "gui.general.prix" : "Prix",
    "csia.repertoire.detailSeance" : "Détail de la séance",
    "gui.general.description" : "Description",
    "csia.repertoire.butFormation" : "But de la formation",
    "csia.repertoire.prealables" : "Préalables",
    "gui.general.fermer" : "Fermer",
    "csia.register" : "S'inscrire"
    

  },
  "en":{
    "gui.general.details.simple" : "Details",
    "emplacement.nom" : "Location",
    "gui.general.dates.dateDebut" : "Start date",
    "gui.general.dates.dateFin" : "End date",
    "gui.general.langue" : "Language",
    "gui.general.prix" : "Price",
    "csia.repertoire.detailSeance" : "Session Detail",
    "gui.general.description" : "Description",
    "csia.repertoire.butFormation" : "Purpose of training",
    "csia.repertoire.prealables" : "Prerequisites",
    "gui.general.fermer" : "Close",
    "csia.register" : "Register"


  }
}
</i18n>

<style scoped>
h2,
h3 {
  word-break: break-word;
}
</style>

<script>
import Vue from "vue";
import VueI18n from "vue-i18n";
import i18n from "../../i18n";
//import Details from "./Details";
import RestApiService from "@/services/rest.api.service.js";

Vue.use(VueI18n);

export default {
  i18n,
  props: {
    info: Object,
    loggedIn: String
  },
  created() {
    // this.$i18n.locale = this.$locale;
    // RestApiService.setLang(this.$locale);
  },
  mounted() {

    this.headers = [
      {
        text: this.$t("gui.general.start_date"),
        value: "dateDébut",
        sortable: false,
      },
      {
        text: this.$t("gui.general.end_date"),
        value: "dateFin",
        sortable: false,
      },
      {
        text: this.$t("gui.general.language"),
        value: "langue",
        sortable: false,
      },
      {
        text: this.$t("gui.general.price"),
        value: "prix",
        sortable: false,
      },
      {
        text: ' ',
        value: "information",
        sortable: false,
      },
      {
        text: '',
        value: "actions",
        align: "right",
        sortable: false,
      },
    ];
  },
  data() {
    return {
      dialog: false,
      headers: [],
      séances: [
        {
          dateDébut: "1",
          dateFin: "2",
          langue: "3",
          prix: "4",
          e: "5",
        },
      ]
    };
  },
  methods: {
    register: function (item, info) {
      if (this.loggedIn) {
        this.$emit("showInformation", item, info);
      } else {
        this.$emit("onNewAccount", item)

        // const array = window.location.pathname.split("/");
        // const lang = array[1]; // en || fr
        // const result = `${lang}/component/users/?view=login&Itemid=666&creerCompte`;
        // window.location.pathname = result;
      }
    },
    ajouterListeAttente (item){
      console.log(item)
      const info = {
        idSeance : item.identifiant
      }
      RestApiService.put('/resto/csia/joomla/catalogue/ajouterAttenteSeance',info)
        .then((r)=>alert(r.data))
    }
  },
};
</script>