<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary-custom"
        small
        v-bind="attrs"
        v-on="on"
      >{{$t('gui.general.details')}}</v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">{{$t('csia.repertoire.detailSeance')}}</span>
      </v-card-title>
      <v-card-text>
        <div v-if="info.description">
          <h5>{{$t('gui.general.description')}}</h5>
          <div v-html="info.description" />
        </div>
        <div v-if="info.buts">
          <h5>{{$t('csia.repertoire.butFormation')}}</h5>
          <div v-html="info.buts" />
        </div>
        <div v-if="info.préalables">
          <h5>{{$t('csia.repertoire.prealables')}}</h5>
          <div v-html="info.préalables" />
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">{{$t('gui.general.fermer')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import VueI18n from "vue-i18n";
import i18n from "../../i18n";
import vuetify from "../../plugins/vuetify";
import RestApiService from "@/services/rest.api.service.js";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(VueI18n);

export default {
  props: {
    info: Object,
  },
  data: function () {
    return {
        dialog: false
    };
  },
  created: function () {
    this.$i18n.locale = this.$locale;
    RestApiService.setTokenHeader(this.$token);
    RestApiService.setLang(this.$locale);
    RestApiService.setServeur(this.$serveur);
  },
  mounted: function () {
    //RestApiService.get("")
  },
  methods: {},
  computed: {},
  vuetify,
  i18n,
};
</script>

<style scoped>
</style>