<template>
  <div>
    <Loading v-if="loading" />
    <div v-else>
      <v-row v-if="panier.items && panier.items.length === 0">
        <v-col cols="12">
          <div>
            <p>{{ $t("cart.your_cart_is_empty") }}</p>
            <v-btn
              class="btn-wrap-text primary-custom"
              @click="$emit('returnToSearch')"
              >{{ $t("register.return_to_training_directory") }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
      <div v-else>
        <v-row>
          <v-col cols="12">
            <h3>{{ $t("gui.general.your_cart") }}</h3>
            <v-card outlined class="my-4">
              <v-card-text>
                <v-row>
                  <v-col cols="8">
                    <h4>{{ $t("ren.description") }}</h4>
                  </v-col>
                  <v-col cols="4" class="text-right">
                    <h4>{{ $t("ren.montant") }}</h4>
                  </v-col>
                </v-row>
                <v-row v-for="item of panier.items" :key="item.id">
                  <v-col cols="8" class="cart-item-label">
                    <h5>
                        <v-chip v-if="!item.disponible"
                          color="red"
                          text-color="white"
                        >
                          {{ $t("cart.item_nonDisponible") }}
                        </v-chip>
                        {{ item.nom }}
                    </h5>

                    <div v-html="item.description"></div>
                  </v-col>
                  <v-col cols="4" class="text-right cart-item-label">
                    <span>{{ item.montant }}</span>
                    <span
                      @click="deleteItem(item.id)"
                      class="delete-item-icon-wrap"
                      ><v-icon>mdi-delete</v-icon></span
                    >
                  </v-col>
                </v-row>
                <v-row style="margin-top: 25px">
                  <v-col cols="8" class="cart-item-label">{{
                    $t("ren.sous-total")
                  }}</v-col>
                  <v-col cols="4" class="text-right cart-item-label">{{
                    panier.sousTotal
                  }}</v-col>
                </v-row>
                <v-row v-for="taxe in panier.taxes" :key="taxe.nom">
                  <v-col cols="8" class="cart-item-label"
                    >{{ taxe.numero }} {{ taxe.nom }}</v-col
                  >
                  <v-col cols="4" class="text-right cart-item-label">{{
                    taxe.montant
                  }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="8" class="cart-item-label">{{
                    $t("gui.general.total")
                  }}</v-col>
                  <v-col cols="4" class="text-right cart-item-label">{{
                    panier.total
                  }}</v-col>
                </v-row>
                <v-row v-if="promoCodes.length > 0">
                  <v-col cols="8" class="cart-item-label">
                    <div>{{ $t("gui.general.promo_code") }}</div>
                  </v-col>
                  <v-col cols="4 text-right">
                    <v-select
                      v-model="choixPromoCode"
                      :items="promoCodes"
                      item-value="code"
                      item-text="longName"
                    ></v-select>
                    <v-btn
                      @click="onAppliquerPromoCode"
                      :disabled="!choixPromoCode"
                      >{{ $t("gui.general.apply") }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12 text-right">
            <p>{{ $t("gui.general.total") }} : {{ panier.total }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title>{{ $t("ren.legal") }}</v-card-title>
              <p class="encartLegal" v-html="encarts.confirmationLegal"></p>
              <v-card-text>
                <div
                  v-for="(waiver, indexWaiver) of waivers"
                  :key="indexWaiver"
                  class="wrap-waiver"
                >
                  <v-dialog v-model="waiver.dialog" width="900">
                    <template v-slot:activator="{ on, attrs }">
                      <div class="d-flex">
                        <v-checkbox
                          v-model="waiver.checked"
                          class="mt-1"
                          :disabled="
                            waiver.signatureRequise &&
                            waiver.signature.length == 0
                          "
                        ></v-checkbox>
                        <v-btn
                          class="wrap-btn-custom waiver-btn"
                          style="text-align: left"
                          text
                          v-bind="attrs"
                          v-on="on"
                        >
                          <span
                            ><u>{{ waiver.nom }}</u></span
                          >
                          <span class="star-require">*</span>
                        </v-btn>
                      </div>
                    </template>
                    <v-card>
                      <v-card-title>{{ waiver.nom }}</v-card-title>
                      <v-card-text>
                        <div v-html="waiver.texte" class="legalText"></div>
                        <div>
                          <hr />
                          <div v-if="waiver.signatureRequise">
                            <v-text-field
                              v-model="waiver.signature"
                              :label="$t('gui.general.name')"
                            ></v-text-field>
                            <v-text-field
                              v-model="waiver.signatureParent"
                              :label="$t('gui.general.parentOrGuardian')"
                            ></v-text-field>
                          </div>
                        </div>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary-custom"
                          text
                          @click="
                            waiver.dialog = false;
                            if (waiver.signature.length > 0)
                              waiver.checked = true;
                          "
                          >Ok</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card>
              <v-card-title>{{ $t("ren.payment") }}</v-card-title>
              <v-card-text>
                <v-radio-group v-model="typePaiement">
                  <v-radio v-for="(type, indexType) of payments" :key="indexType"
                    :label="type.texte"
                    :value="type.clef"
                  ></v-radio>
                </v-radio-group>
                <div>
                  <v-dialog v-model="dialogPolicy" width="500">
                    <template v-slot:activator="{ on, attrs }">
                      <div class="d-flex">
                        <v-checkbox
                          v-model="checkPaymentPolicy"
                          class="mt-0"
                        ></v-checkbox>
                        <v-btn
                          class="wrap-btn-custom"
                          text
                          v-bind="attrs"
                          v-on="on"
                        >
                          <span
                            ><u>{{ $t("ren.accordPaiement") }}</u></span
                          >
                          <span class="star-require">*</span>
                        </v-btn>
                      </div>
                    </template>
                    <v-card>
                      <v-card-title>{{
                        $t("ren.cancellation-policy")
                      }}</v-card-title>
                      <v-card-text v-html="panier.textePolitique"></v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary-custom"
                          text
                          @click="dialogPolicy = false"
                          >Ok</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="availabilityIssue" max-width="500">
                    <v-card>
                      <v-card-title>{{$t("cart.availabilityIssue_popup_title")}}</v-card-title>
                      <v-card-text>
                        <p v-html="encarts.itemNonDisponible"/>
                      </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                        <v-btn
                          color="primary-custom"
                          text
                          @click="availabilityIssue = !availabilityIssue"
                          >Ok</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col cols="12" sm="4">
            <v-btn
              style="width: 100%"
              class="btn-wrap-text primary-custom"
              @click="$emit('returnToSearch')"
              >{{ $t("register.return_to_training_directory") }}</v-btn
            >
          </v-col>
          <v-col class="text-right" cols="12" sm="8">
            <v-btn
              color="primary-custom"
              @click="payer()"
              :disabled="!peutPayer"
              >{{ $t("ren.payment") }}</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueI18n from "vue-i18n";
import i18n from "../../../i18n";
import vuetify from "../../../plugins/vuetify";
import RestApiService from "@/services/rest.api.service.js";
import Loading from "../../Loading";

Vue.use(VueI18n);

export default {
  components: {
    Loading,
  },
  data() {
    return {
      dialog: {},
      dialogPolicy: false,
      legalCheck: {},
      checkPaymentPolicy: false,
      typePaiement: null,
      encarts: {},
      promoCodes: [],
      choixPromoCode: null,
      panier: {},
      loading: true,
      waivers: {},
      payments: [],
      availabilityIssue: false
    };
  },
  created() {
    this.$i18n.locale = this.$locale;
    RestApiService.setTokenHeader(this.$token);
    RestApiService.setLang(this.$locale);
    RestApiService.setServeur(this.$serveur);
  },
  mounted() {
    const encartUrl = `/resto/csia/joomla/util/encart/${this.$locale}`;

    const promises = [
      RestApiService.get(
        `${encartUrl}/.renouvellement.confirmation.legal`
      ).then((response) => (this.encarts.confirmationLegal = response.data)),

      RestApiService.get(
        `${encartUrl}/.csia.paiement.probleme.itemNonDisponible`
      ).then((response) => {
          this.encarts.itemNonDisponible = response.data
        }
      ),

      RestApiService.get("/resto/csia/joomla/usager/codePromo").then(
        (response) => {
          this.promoCodes = response.data;
          this.promoCodes.forEach(
            (x) => (x.longName = `${x.code} - ${x.description}`)
          );
        }
      ),
      RestApiService.get("/resto/csia/joomla/panierAchat/panier/listeTypePaiements").then(
        (response) => {
          this.payments = response.data;
        }
      ),

      this.getCart(),
    ];

    Promise.all(promises).finally(() => (this.loading = false));
  },
  methods: {
    removeDuplicates: function () {
      const filteredArr = this.panier.items.reduce((acc, current) => {
        const x = acc.find((item) => {
          // can't use the id since the id is incremented each time you add an item to the cart
          return (
            item.nom === current.nom &&
            item.description === current.description &&
            item.montant === current.montant
          );
        });
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      this.panier.items = filteredArr;
    },
    getCart: function () {
      return RestApiService.get("/resto/csia/joomla/panierAchat").then(
        (response) => {
          this.panier = response.data;
          this.removeDuplicates();
          this.waivers = this.panier.waivers.map((w) => ({
            ...w,
            checked: false,
            signature: "",
            signatureParent: "",
          }));
        }
      );
    },
    deleteItem: function (index) {
      RestApiService.delete(
        "/resto/csia/joomla/panierAchat/items/" + index
      ).then(() => {
        this.getCart();
      });
    },
    onAppliquerPromoCode: function () {
      const infoPromo = {
        codePromo: this.choixPromoCode,
      };
      RestApiService.post(
        "/resto/csia/joomla/panierAchat/ajouter/promotion/",
        infoPromo
      ).then((result) => {
        if (result.data.reponse === "erreur") {
          alert(result.data.messageUsager);
        }

        this.getCart();
      });
    },
    isSnowtest: function () {
      return window.location.hostname.includes("snowtest");
    },
    payer: function () {
      const signatures = this.waivers.map((w) => ({
        nom: w.nom,
        signature: w.signature,
        signatureParent: w.signatureParent,
      }));
      let url = window.location.href;
      // enlever tout param... /en/certification-en/search-and-register?type=ActiviteSeance&identifiant=17126
      if (url.indexOf('?')!==-1){
        url = url.substring(0,url.indexOf('?'))
      }

      const paiement = {
        typePaiement: this.typePaiement,
        urlRetour: `${url}?paymentSuccess=1`,
        listeSignatures: signatures,
      };

      RestApiService.put("/resto/csia/joomla/panierAchat/paiement/payer", paiement)
        .then((response) => {
          if (response.status === 200) {
            if(response.data.message === "Availibility issue"){
              this.availabilityIssue = !this.availabilityIssue
              this.getCart()
            } else {
              // Régler un problème de window.open dans Safari
              const linkElement = document.createElement('a');
              linkElement.href = response.data.url;
              linkElement.target = '_self';
              window.document.body.appendChild(linkElement);
              linkElement.click();
            }
          } else {
            alert(response.status);
          }
        })
        .catch(() => {
          this.getCart()
        });
    },
  },
  computed: {
    peutPayer: function () {
      const checkLegal = this.waivers.every((waiver) => waiver.checked);
      return checkLegal && this.checkPaymentPolicy && this.typePaiement;
    },
  },
  vuetify,
  i18n,
};
</script>

<style scoped>
.legalText {
  max-height: 300px;
  overflow-y: scroll;
}

.wrap-btn-custom {
  white-space: break-spaces;
  flex: initial;
  display: block;
  height: auto !important;
}

.encartLegal {
  margin: 0;
  font-size: 0.9rem;
  padding: 0 15px;
}

.star-require {
  color: red;
  font-size: 18px;
}

.waiver-btn {
  text-align: left;
}

.cart-item-label {
  font-size: 1.1rem;
}

.delete-item-icon-wrap {
  padding-left: 10px;
  cursor: pointer;
}

.btn-wrap-text {
  display: block;
  width: 100%;
  word-break: keep-all;
  text-align: center;
  white-space: normal;
  padding: 8px 16px !important;
  height: 100% !important;
  min-height: 36px;
}
</style>