<template>
  <div>
    <div v-if="!showNext">
      <Profil nodownload :showPrefCom="false" :profilValide.sync="profilValide" />
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>{{$t('csia.organizations')}}</v-card-title>
            <v-data-table
              :no-data-text="$t('gui.general.aucun')"
              :no-results-text="$t('gui.general.no_results')"
              hide-default-footer
              disable-filtering
              disable-sort
              disable-pagination
              :headers="partenairesHeader"
              :items="partenairesRows"
            >
              <template v-slot:item.partner="{ item }">
                <v-select
                  style="width: 80%; margin-left: auto"
                  v-model="item.partner"
                  :items="formattedListPartenaireValeur"
                ></v-select>
              </template>
              <template v-slot:item.numero_de_membre="{ item }">
                <v-text-field v-model="item.numero_de_membre"></v-text-field>
              </template>
              <template v-slot:item.action="{ item }">
                <v-btn small color="error" @click="removeRow(item.index)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
            </v-data-table>
            <v-btn
              :loading="partenairesSaveLoading"
              :disabled="partenairesSaveLoading"
              @click="saveMemberships"
              class="primary-custom mx-2 my-2"
              >{{ $t("gui.general.sauvegarder") }}</v-btn
            >
            <v-btn @click="addAssociation" class="primary-custom mx-2 my-2">{{
              $t("gui.general.ajouter")
            }}</v-btn>
            <p class="green--text" style="margin-left: 10px">
              {{ saveStatus }}
            </p>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="text-right">
            <v-btn :disabled="!profilValide" class="primary-custom" @click="next()">
              {{ $t("gui.general.next") }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
    <Sessions
      v-if="showNext && seance.type === 'ActiviteSeance'"
      :formation="formation"
      :idSeance="seance.identifiant"
      @returnToSearch="$emit('returnToSearch')"
    />
    <Evenement
      v-if="showNext && seance.type !== 'ActiviteSeance'"
      :formation="formation"
      :idEvenement="seance.identifiant"
      @returnToSearch="$emit('returnToSearch')"
    />
  </div>
</template>

<script>
import Vue from "vue";
import VueI18n from "vue-i18n";
import i18n from "../../../i18n";
import vuetify from "../../../plugins/vuetify";
import RestApiService from "@/services/rest.api.service.js";
import "@mdi/font/css/materialdesignicons.css";
import Profil from "../../profil/Profil";
import Sessions from "./Sessions";
import Evenement from "./Evenement";

Vue.use(VueI18n);

export default {
  props: {
    formation: Object,
    seance: Object,
  },
  components: {
    Profil,
    Sessions,
    Evenement,
  },
  data: function () {
    return {
      showNext: false,
      profilValide: false,
      partenairesHeader: [],
      partenairesRows: [],
      partenairesIndex: 0,
      formattedListPartenaireValeur: [],
      partenairesSaveLoading: false,
    };
  },
  created: function () {
    this.$i18n.locale = this.$locale;
    RestApiService.setTokenHeader(this.$token);
    RestApiService.setLang(this.$locale);
    RestApiService.setServeur(this.$serveur);
    window.scrollTo(0, 0);
  },
  mounted: function () {
    this.partenairesHeader = [
      {
        text: this.$t("renouvellement.partner"),
        value: "partner",
        width: "200",
      },
      {
        text: this.$t("renouvellement.numero_de_membre"),
        value: "numero_de_membre",
      },
      { text: this.$t("renouvellement.action"), value: "action" },
    ];

    RestApiService.get("/resto/csia/joomla/usager/listeChoixMenu").then(
      (response) => {
        const formattedListPartenaireValeur = [];

        for (const i of response.data.listePartenaireValeurs) {
          formattedListPartenaireValeur.push({
            value: i.nomPartenaire,
            text: i.valeurI18N,
          });
        }

        this.formattedListPartenaireValeur = formattedListPartenaireValeur;
      }
    );

    RestApiService.get("/resto/csia/joomla/liste/partenaire").then(
      (response) => {
        for (const i of response.data) {
          this.partenairesRows.push({
            partner: i.nomPartenaire,
            numero_de_membre: i.numeroMembre,
          });
        }
      }
    );
  },
  methods: {
    next: function () {
      this.showNext = true;
      console.log(11);
    },
    addAssociation: function () {
      this.partenairesRows.push({
        index: this.partenairesIndex++,
        partner: this.formattedListPartenaireValeur[0].value,
      });
    },
    removeRow: function (index) {
      this.partenairesRows = this.partenairesRows.filter(
        (row) => row.index != index
      );
    },
    saveMemberships: function () {
      this.partenairesSaveLoading = true;

      const partenaires = [];

      for (const i of this.partenairesRows) {
        partenaires.push({
          nomPartenaire: i.partner,
          numeroMembre: i.numero_de_membre,
        });
      }

      const infoPartenaire = { listePartenaire: partenaires };

      RestApiService.put(
        "/resto/csia/joomla/liste/partenaire",
        infoPartenaire
      ).finally(() => {
        this.partenairesSaveLoading = false;
      });
    },
  },
  computed: {},
  vuetify,
  i18n,
};
</script>

<style scoped>
</style>