import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import WalletEn from './WalletEn';
import WalletFr from './WalletFr';
import WalletAndroidEn from './WalletAndroidEn';
import WalletAndroidFr from './WalletAndroidFr';


Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        primary: '##ff000',
        secondary: '#ff000',
        anchor: '#ff000',
    },
    icons: {
        values: {
            walleten: { // name of our custom icon
                component: WalletEn, // our custom component
            },
            walletfr: { // name of our custom icon
                component: WalletFr, // our custom component
            },
            walletAndroidEn:{
                component: WalletAndroidEn
            },
            walletAndroidFr:{
                component: WalletAndroidFr
            }
        },
    },
});
