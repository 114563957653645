<template>
  <v-app class="wrap-pref-communication">
    <v-card style="padding-bottom: 15px;">
      <v-card-title>{{$t('profil.preferences_communications')}}</v-card-title>
      <v-row>
        <v-col cols="12" sm="8">
          <v-card-text v-html="encarts.communicationOrdre"></v-card-text>
        </v-col>
        <v-col cols="12" sm="4" class="d-flex justify-center align-center">
          <span>{{ $t('gui.general.no') }}</span>
          <v-switch
            @change="$emit('updatePrefComInfo', info)"
            flat
            dense
            class="common-switch"
            hide-details
            v-model="info.recevoirPromotion"
          ></v-switch>
          <span style="margin-left: -8px;">{{ $t('gui.general.yes') }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="8">
          <v-card-text v-html="encarts.communicationPartenaires"></v-card-text>
        </v-col>
        <v-col cols="12" sm="4" class="d-flex justify-center align-center">
          <span>{{ $t('gui.general.no') }}</span>
          <v-switch
            @change="$emit('updatePrefComInfo', info)"
            flat
            dense
            class="common-switch"
            hide-details
            v-model="info.recevoirPromotionTiers"
          ></v-switch>
          <span style="margin-left: -8px;">{{ $t('gui.general.yes') }}</span>
        </v-col>
      </v-row>
      <div v-if="!commSeulement">
        <v-row>
          <v-col cols="12">
            <v-card-text class="pref-com-subtitle">{{ $t('profil.lang_parle') }}</v-card-text>
            <v-icon @click="addSpokenLang" style="display: inline-block">mdi-plus-circle</v-icon>
          </v-col>
        </v-row>
        <div v-for="(next, index) of info.listeLanguesParlees" :key="index">
          <v-row>
            <v-col cols="12" class="less-padding">
              <div class="d-flex justify-center align-center" style="margin:0 15px;">
                <v-select
                  @change="$emit('updatePrefComInfo', info)"
                  dense
                  hide-details
                  outlined
                  :items="listeLangue"
                  item-text="libelle"
                  item-value="code"
                  v-model="info.listeLanguesParlees[index]"
                ></v-select>
                <v-btn
                  @click="removeSpokenLang(index)"
                  :disabled="info.listeLanguesParlees.length === 1"
                  small
                  class="primary-custom"
                  style="margin-left:15px"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col cols="12">
            <v-card-text class="pref-com-subtitle">{{ $t('profil.lang_ecrite') }}</v-card-text>
            <v-icon @click="addWrittenLang" style="display: inline-block">mdi-plus-circle</v-icon>
          </v-col>
        </v-row>
        <div v-for="(next, index) of info.listeLanguesEcrites" :key="index">
          <v-row>
            <v-col cols="12" class="less-padding">
              <div class="d-flex justify-center align-center" style="margin:0 15px;">
                <v-select
                  @change="$emit('updatePrefComInfo', info)"
                  dense
                  hide-details
                  outlined
                  :items="listeLangue"
                  item-text="libelle"
                  item-value="code"
                  v-model="info.listeLanguesEcrites[index]"
                ></v-select>
                <v-btn
                  @click="removeWrittenLang(index)"
                  :disabled="info.listeLanguesEcrites.length === 1"
                  small
                  class="primary-custom"
                  style="margin-left:15px"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col cols="12">
            <v-card-text class="pref-com-subtitle">{{ $t('profil.lang_maternelle') }}</v-card-text>
            <v-icon @click="addNativeLang" style="display: inline-block">mdi-plus-circle</v-icon>
          </v-col>
        </v-row>
        <div v-for="(next, index) of info.listeLanguesMaternelles" :key="index">
          <v-row>
            <v-col cols="12" class="less-padding">
              <div class="d-flex justify-center align-center" style="margin:0 15px;">
                <v-select
                  @change="$emit('updatePrefComInfo', info)"
                  dense
                  hide-details
                  outlined
                  :items="listeLangue"
                  item-text="libelle"
                  item-value="code"
                  v-model="info.listeLanguesMaternelles[index]"
                ></v-select>
                <v-btn
                  @click="removeNativeLang(index)"
                  small
                  class="primary-custom"
                  style="margin-left:15px"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card>
  </v-app>
</template>

<script>
import Vue from "vue";
import VueI18n from "vue-i18n";
import i18n from "../../i18n";
import vuetify from "../../plugins/vuetify";
import "@mdi/font/css/materialdesignicons.css";
import RestApiService from "@/services/rest.api.service.js";

Vue.use(VueI18n);

export default {
  vuetify,
  i18n,
  data: function () {
    return {
      info: {},
      listeLangue: [],
      test: "",
      encarts: {
        communicationOrdre: "",
        communicationPartenaires: "",
      },
    };
  },
  props: {
    commSeulement: {
      type: Boolean,
      default: false,
    }
  },
  created: function () {
    this.$i18n.locale = this.$locale;
    RestApiService.setTokenHeader(this.$token);
    RestApiService.setLang(this.$locale);
    RestApiService.setServeur(this.$serveur);
  },
  mounted: function () {
    const encartUrl = `/resto/csia/joomla/util/encart/${this.$locale}`;

    const promises = [
      RestApiService.get(`${encartUrl}/.prefComm.CommunicationOrdre`).then(
        (response) => (this.encarts.communicationOrdre = response.data)
      ),
      RestApiService.get(
        `${encartUrl}/.prefComm.CommunicationPartenaires`
      ).then(
        (response) => (this.encarts.communicationPartenaires = response.data)
      ),
      this.getInfo(),
      this.getListeLangue(),
    ];

    Promise.all(promises)
      .then(() => {
        // I must add this code since newly created users doesn't have those prefs by default, even if they ARE required! What kind of BD architecture is this?!

        if (this.info.listeLanguesParlees.length === 0) {
          this.info.listeLanguesParlees.push("fr");
        }

        if (this.info.listeLanguesEcrites.length === 0) {
          this.info.listeLanguesEcrites.push("fr");
        }
      })
      .catch((reason) => {
        console.error(reason);
        alert("One or many requests failed in Communication Preferences");
      });
  },
  methods: {
    removeSpokenLang: function (index) {
      this.info.listeLanguesParlees.splice(index, 1);
      this.$emit("updatePrefComInfo", this.info);
    },
    addSpokenLang: function () {
      this.info.listeLanguesParlees.push("fr");
      this.$emit("updatePrefComInfo", this.info);
    },
    removeWrittenLang: function (index) {
      this.info.listeLanguesEcrites.splice(index, 1);
      this.$emit("updatePrefComInfo", this.info);
    },
    addWrittenLang: function () {
      this.info.listeLanguesEcrites.push("fr");
      this.$emit("updatePrefComInfo", this.info);
    },
    removeNativeLang: function (index) {
      this.info.listeLanguesMaternelles.splice(index, 1);
      this.$emit("updatePrefComInfo", this.info);
    },
    addNativeLang: function () {
      this.info.listeLanguesMaternelles.push("fr");
      this.$emit("updatePrefComInfo", this.info);
    },
    getInfo: function () {
      return RestApiService.get(
        "/resto/csia/joomla/usager/preferenceCommunication"
      ).then((response) => {
        this.info = response.data;
      });
    },
    getListeLangue: function () {
      return RestApiService.get("/resto/csia/joomla/listeLangue").then(
        (response) => {
          this.listeLangue = response.data;
        }
      );
    },
  },
  computed: {},
};
</script>

<style scoped>
.v-card {
  width: 100%;
}
.v-card__text {
  font-size: 1.1rem;
}
.common-switch {
  margin: 0 15px;
}
.pref-com-subtitle {
  width: initial;
  display: inline-block;
}
.less-padding {
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
<style>
.wrap-pref-communication > .v-application--wrap {
  min-height: auto;
  margin-bottom: 25px;
}
</style>