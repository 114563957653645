<template>
  <v-app>
    <div class="wrap-catalogue">
      <!-- <evenement idEvenement="46"></evenement> -->

      <Loading v-if="loading" />

      <v-row v-if="paymentSuccess" style="margin: 0 15px" class="text-center">
        <v-col cols="12">
          <div>
            <p v-html="encartPaymentSuccess"></p>
            <v-btn @click="goToInvoices()" class="btn-wrap-text primary-custom">{{ $t("gui.general.go_to_invoices")
              }}</v-btn>
          </div>
        </v-col>
      </v-row>

      <div v-if="
          !loading &&
          !displayCurrentFormation &&
          !showPayment &&
          !showNewAccount
">
        <p v-html="encartInformationImportante"></p>
        <p v-html="encartRecherche"></p>
        <v-row>
          <v-col cols="12" sm="4" class="text-center">
            <BoiteFiltre :titre="$t('régions')" :items="listeRégions" :choix.sync="choix1" />
          </v-col>
          <v-col cols="12" sm="4" class="text-center">
            <BoiteFiltre :titre="$t('montagnes')" :items="listeMontagnesFiltrée" :choix.sync="choix2" />
          </v-col>
          <v-col cols="12" sm="4" class="text-center">
            <BoiteFiltre :titre="$t('catégories')" :items="listeCatégories" :choix.sync="choix3" />
          </v-col>
        </v-row>
        <div>
          <v-row>
            <v-col cols="12" md="4">
              <v-combobox v-model="mots" :items="listeNomsFormations"
                :label="$t('csia.repertoire.rechercheActivitesNom')" prepend-icon="mdi-magnify" clearable></v-combobox>
            </v-col>
            <v-col cols="12" md="4">
              <v-menu v-model="menuFrom" :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field class="red--text" v-model="dateDébut" :label="$t('choixDate.debut')" readonly
                    v-bind="attrs" v-on="on" prepend-icon="mdi-calendar-arrow-right"></v-text-field>
                </template>
                <v-date-picker v-model="dateDébut" @input="menuFrom = false" :locale="lang"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="4">
              <v-menu v-model="menuTo" :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="dateFin" :label="$t('choixDate.fin')" readonly v-bind="attrs" v-on="on"
                    prepend-icon="mdi-calendar-arrow-left"></v-text-field>
                </template>
                <v-date-picker v-model="dateFin" @input="menuTo = false" :locale="lang"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-btn :loading="recherche" :disabled="recherche" class="primary-custom btn-catalogue-search"
            @click="chercher()">
            {{ $t("gui.general.recherche") }}
            <!-- <v-progress-circular size="16" width="3" indeterminate color="white" v-if="recherche"></v-progress-circular> -->
          </v-btn>
          <v-btn class="secondary mx-3" @click="reset()">{{
            $t("gui.general.reinitialiser")
          }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn id="csia-card-btn" color="#e8e8e8" @click="allerPanier()" v-if="panier && panier.items.length > 0">
            <v-icon>mdi-cart</v-icon>({{ panier.items.length }})
            {{ $t("panier-inscription") }}
          </v-btn>
        </v-row>
        <div class="wrap-formations" v-if="formations">
          <div v-if="formations.listeFormations.length > 0">
            <div v-for="formation in formations.listeFormations" :key="formation.nom">
              <CSIAFormation class="mb-3" :loggedIn="userToken" @showInformation="showInformation"
                @onNewAccount="onNewAccount" :info="formation" />
            </div>
          </div>
          <div v-else>{{ $t("gui.general.no_results") }}</div>
        </div>
      </div>
      <NewAccount v-if="showNewAccount" :seance="currentSeance" @quitNewAccount="quitNewAccount()">

      </NewAccount>
      <div v-if="
        !loading &&
        (displayCurrentFormation ||
          showPayment) &&
        !showNewAccount
      ">
        <p v-html="encartInformationImportante"></p>
      </div>
      <Information v-if="displayCurrentFormation" :formation="currentFormation" :seance="currentSeance"
        @returnToSearch="returnToSearch()" />
      <Payment v-if="showPayment" @returnToSearch="returnToSearch()" />
    </div>
  </v-app>
</template>

<i18n>
{
  "fr":{
    "csia.repertoire.recherche.titre" : "<p><strong>Recherche et inscription</strong> - Sélectionnez un ou plusieurs filtres pour des résultats plus précis.</p>",
    "csia.repertoire.rechercheActivitesNom" : "Rechercher des activités par nom",
    "choixDate.debut" : "Du",
    "choixDate.fin" : "Au",
    "régions" : "Régions",
    "montagnes" : "Montagnes",
    "catégories" : "Catégories",
    "gui.general.recherche": "Recherche",
    "gui.general.reinitialiser": "Réinitialiser"

  },
  "en":{
    "csia.repertoire.recherche.titre" : "<p><strong>Search and Register</strong> - To narrow your search, please select one or more of the following filters.</p>",
    "csia.repertoire.rechercheActivitesNom" : "Search activities by name",
    "choixDate.debut" : "From",
    "choixDate.fin" : "To",
    "régions" : "Regions",
    "montagnes" : "Mountains",
    "catégories" : "Category",
    "gui.general.recherche": "Search",
    "gui.general.reinitialiser": "Reset"
  }
}
</i18n>

<script>
import Vue from "vue";
import VueI18n from "vue-i18n";
import "@mdi/font/css/materialdesignicons.css";

import i18n from "../../i18n";

import vuetify from "../../plugins/vuetify";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import BoiteFiltre from "./BoiteFiltre";
import CSIAFormation from "./CSIAFormation";
import RestApiService from "@/services/rest.api.service.js";
import Information from "./register/Information";
import Loading from "../Loading";
import Payment from "./register/Payment";
import NewAccount from "./register/NewAccount";

// import Evenement from "./register/Evenement";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueI18n);

export default {
  components: {
    BoiteFiltre,
    CSIAFormation,
    Information,
    Loading,
    Payment,
    NewAccount,
    // Evenement,
  },
  data() {
    return {
      menu: false,
      menuFrom: false,
      menuTo: false,
      listeNomsFormations: [],
      items: [],
      mots: "",
      recherche: false,
      formations: null,
      choix1: [],
      choix2: [],
      choix3: [],
      dateDébut: this.getToday(),
      dateFin: null,
      listeRégions: [],
      listeMontagnes: [],
      listeMontagnesFiltrée: [],
      listeCatégories: [],
      lang: "",
      encartSubTitle: "",
      encartPaymentSuccess: "",
      encartRecherche: "",
      encartInformationImportante: "",
      displayCurrentFormation: false,
      currentFormation: {},
      currentSeance: {},
      userToken: this.$token,
      loading: true,
      showPayment: false,
      panier: null,
      paymentSuccess: false,
      showNewAccount: false,
    };
  },
  created() {
    //this.$vuetify.theme.primary = "#4caf50";
    this.$i18n.locale = this.$locale;
    this.lang = this.$locale;
    RestApiService.setLang(this.$locale);

    if (this.$token) {
      RestApiService.setTokenHeader(this.$token);
    }
    if (this.$serveur == null) {
      RestApiService.setServeur("http://localhost:8080/Acrobatik");
    } else {
      RestApiService.setServeur(this.$serveur);
    }

    // Initialiser la liste des régions

    const promises = [
      RestApiService.get("/resto/csia/v1/regions")
        .then((response) => {
          let t = response.data.regions.map((r) => {
            return {
              id: r.regionId,
              libellé: r.regionId + " - " + r.regionName,
            };
          });
          t.sort((a, b) => a.id - b.id);
          // on retire id 7,8,9
          t = t.filter((r) => r.id < 7);
          this.listeRégions = t;
        })
        .catch((error) => alert(error)),

      // Initialiser la liste des montagnes

      RestApiService.get("/resto/csia/v1/locations")
        .then((response) => {
          let t = response.data.locations.map((loc) => {
            return {
              id: loc.locationId,
              libellé: loc.locationName,
              regionId: loc.regionId,
            };
          });
          this.listeMontagnes = t;
          this.listeMontagnesFiltrée = t;
        })
        .catch((error) => alert(error)),

      // Initialiser la liste des catégories

      RestApiService.get("/resto/csia/v1/categories").then((response) => {
        let t = response.data.categories.map((cat) => {
          return {
            id: cat.categoryId,
            libellé: cat.categoryName,
          };
        });
        this.listeCatégories = t;
      }),
    ];

    if (this.$token) {
      this.majPanier();
    }

    Promise.all(promises)
      .catch(() => {
        alert("One or many requests failed in Catalogue");
      })
      .catch((error) => alert(error));
  },
  mounted() {
    const encartUrl = `/resto/csia/joomla/util/encart/${this.$locale}`;

    RestApiService.get(
      `${encartUrl}/.csia.moteurderecherche.inscription.information`
    ).then((response) => (this.encartSubTitle = response.data));

    RestApiService.get(`${encartUrl}/.inscription.confirmation.paiement`).then(
      (response) => (this.encartPaymentSuccess = response.data)
    );

    RestApiService.get(`${encartUrl}/.csia.importantInformation`).then(
      (response) => (this.encartInformationImportante = response.data)
    );

      RestApiService.get(`${encartUrl}/.csia.searchengine.registration.information`).then(
      (response) => (this.encartRecherche = response.data)
    );

    RestApiService.get("/resto/csia/joomla/catalogue/nomFormations")
      .then((response) => (this.listeNomsFormations = response.data))
      .catch((error) => alert(error));

    this.loading = false; // correct handling will have to be done
    const url_string = window.location.href;
    const url = new URL(url_string);
    const success = url.searchParams.get("paymentSuccess");

    if (success == 1) {
      this.paymentSuccess = true;
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const type = urlParams.get("type");
    const identifiant = urlParams.get("identifiant");

    if (type && identifiant) {
      this.currentSeance = {
        type: type,
        identifiant: identifiant,
      };
      if (this.userToken){
        this.displayCurrentFormation = true;
      }
      else{
        this.onNewAccount(this.currentSeance)
      }
    }
  },
  computed: {},
  watch: {
    choix1: function (listeChoixRégions) {
      if (listeChoixRégions.length == 0) {
        this.listeMontagnesFiltrée = this.listeMontagnes.sort((a, b) =>
          a.libellé.localeCompare(b.libellé)
        );
        return;
      }

      // enlever les montagnes qui ne sont plus dans la liste des régions
      this.choix2 = this.choix2.filter((mid) => {
        let obj = this.listeMontagnes.find((m) => m.id == mid);
        let r = listeChoixRégions.includes(obj.regionId);
        return r;
      });

      this.listeMontagnesFiltrée = this.listeMontagnes
        .filter((m) => listeChoixRégions.includes(m.regionId))
        .sort((a, b) => a.libellé.localeCompare(b.libellé));
    },
  },
  methods: {
    goToInvoices: function () {
      let newUrl = window.location.href.replace("?paymentSuccess=1", "");
      const split = newUrl.split("/");
      newUrl = `${split[0]}//${split[2]}/${split[3]}/${
        this.$locale === "fr"
          ? "zone-membre/mes-factures-et-recus-dimpots"
          : "member-area/my-invoices-and-receipts"
      }`;
      window.location.href = newUrl;
    },
    quitNewAccount: function () {
      this.showNewAccount = false
    },
    showInformation: function (seance, formation) {
      this.displayCurrentFormation = true;
      this.currentFormation = formation;
      this.currentSeance = seance;
    },
    onNewAccount: function (seance) {
      this.showNewAccount = true;
      this.currentSeance = seance;
    },
    getToday: function () {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0");
      const yyyy = today.getFullYear();

      return yyyy + "-" + mm + "-" + dd;
    },
    reset: function () {
      this.mots = "";
      this.choix1 = [];
      this.choix2 = [];
      this.choix3 = [];
      this.dateDébut = null;
      this.dateFin = null;
    },
    returnToSearch : function () {
      this.displayCurrentFormation = false    
      this.showPayment = false
      window.scrollTo(0, 0)  
      
    },
    chercher: function () {
      const param = {
        dateDebut: this.dateDébut,
        dateFin: this.dateFin,
        listeRegionsId: this.choix1,
        listeEmplacementsId: this.choix2,
        listeCategoriesId: this.choix3,
        mots: this.mots,
      };

      this.recherche = true;

      RestApiService.post("/resto/csia/joomla/catalogue/recherche", param)
        .then((reponse) => {
          this.recherche = false;
          this.formations = reponse.data;
          console.log(this.formations)
        })
        .catch((error) => {
          alert(error);
          this.recherche = false;
        });
    },
    majPanier() {
      RestApiService.get("/resto/csia/joomla/panierAchat").then((response) => {
        this.panier = response.data;
      });
    },
    allerPanier() {
      this.showPayment = true;
    },
  },
  vuetify,
  i18n,
};
</script>

<style scoped>
.filtre {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.wrap-formations {
  padding: 15px 0px;
}

.wrap-catalogue {
  padding: 10px;
}

.btn-catalogue-search {
  margin-bottom: 10px;
}
</style>
