<template>
  <v-app>
    <div>
      <div v-if="!showPayment">
        <v-img :src="evenement.urlMedia"></v-img>
        <v-card class="wrap-evenement-desc">
          <v-card-title
            >{{ evenement.nom }}
            <v-card-subtitle>
              <strong
                >{{ $t("csia.catalogue.evenement.datesEvenement") }} :
              </strong>
              {{ evenement.infoDateEvenement }}<br />
              <strong
                >{{ $t("csia.catalogue.evenement.emplacement") }} :
              </strong>
              {{ evenement.infoEmplacement }}<br />
            </v-card-subtitle>
          </v-card-title>
          <v-card-text>
            <div v-html="evenement.description"></div>
          </v-card-text>
        </v-card>

        <div v-if="!evenement.inscriptionPossible">
          <div v-html="evenement.htmlMessageUtilisateur" />
        </div>
        <div v-if="evenement.inscriptionPossible">
          <v-card>
            <v-card-title>{{ $t("catalogue.packages") }}</v-card-title>
            <v-card-text>
              <v-radio-group v-model="rgForfaits" hide-details>
                <v-radio
                  v-for="forfait of evenement.forfaits"
                  :key="forfait.nom"
                  :value="forfait"
                >
                  <template v-slot:label>
                    {{ forfait.nom }}
                    <span v-if="forfait.prix">({{ forfait.prix }})</span>
                    <small class="ml-4">{{ forfait.description }}</small>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-card-text>
          </v-card>
          <v-card v-if="listeActivitesDispo.length > 0">
            <v-card-text>
              <v-btn-toggle style="width: 100%">
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    v-for="activite of listeActivitesDispo"
                    :key="activite.id"
                  >
                    <v-card>
                      <v-card-title style="word-break: normal">
                        {{ activite.nom }}
                        <div v-if="activite.nombrePlaces !== null">
                          <v-card-subtitle>
                            <strong
                              >{{
                                $t("csia.catalogue.evenement.placesDisponible")
                              }}
                              :
                            </strong>
                            {{ activite.nombrePlaces }}
                          </v-card-subtitle>
                        </div>
                      </v-card-title>
                      <v-card-text>
                        <div v-if="activite.description">
                          <div v-if="isAfficherDescription(activite)">
                            <div v-html="activite.description"></div>
                          </div>
                          <v-btn
                            text
                            @click="listeAfficherDescription.push(activite.id)"
                            color="primary"
                            v-if="!isAfficherDescription(activite)"
                            >{{ $t("affiliation.read-more") }}</v-btn
                          >
                          <v-btn
                            text
                            @click="removeInAfficherDescription(activite)"
                            color="primary"
                            v-if="isAfficherDescription(activite)"
                            >{{ $t("affiliation.read-less") }}</v-btn
                          >
                        </div>
                        <v-row style="margin: 0px">
                          <v-btn
                            :value="activite.id"
                            :disabled="!activite.inscriptionPossible"
                            @click="onSelectionActivite(activite)"
                            :class="
                              activiteIsSelected(activite)
                                ? 'primary-custom'
                                : 'secundary'
                            "
                          >
                            <v-icon
                              style="color: white"
                              v-if="activiteIsSelected(activite)"
                              small
                              class="pr-3"
                            >
                              mdi-check-bold
                            </v-icon>
                            <span v-if="!activiteIsSelected(activite)">{{
                              $t("catalogue.select_this_activity")
                            }}</span>
                            <span v-if="activiteIsSelected(activite)">{{
                              $t("catalogue.unselect_this_activity")
                            }}</span>
                          </v-btn>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-btn-toggle>
            </v-card-text>
          </v-card>

          <v-card class="mt-3" v-if="infoAchatArticles.length > 0">
    ÷       <v-card-title>
              {{ $t("catalogue.evenement.activites") }}</v-card-title
            >
            <v-card-text>
              <div v-for="article of infoAchatArticles" :key="article.id">
                <v-row>
                  <v-col>
                    <span style="font-style: italic">{{ article.nom }} </span>
                    <v-text-field
                      :label="article.description"
                      v-model="article.quantite"
                      type="number"
                      :readonly="!article.articleDisponible"
                      :min="0"
                      :max="article.nombreArticleDisponible || Number.MAX_VALUE"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <div>
                      Total : {{ (article.quantite * article.prix) | montant }}
                    </div>
                    <div v-if="article.quantiteMaximum > 0">
                      {{ $t("catalogue.limit") }} :
                      {{ article.nombreArticleDisponible }}
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
        </div>
        <v-row>
          <v-col cols="12" sm="6">
            <v-btn
              class="btn-wrap-text primary-custom"
              @click="$emit('returnToSearch')"
              >{{ $t("register.return_to_training_directory") }}</v-btn
            > </v-col
          ><v-col cols="12" sm="6" class="text-right">
            <v-btn
              class="primary-custom"
              @click="addToCart()"
              v-if="evenement.inscriptionPossible"
            >
              {{ $t("gui.general.add_to_cart") }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <Payment v-else @returnToSearch="$emit('returnToSearch')" />
    </div>
  </v-app>
</template>

<script>
import Vue from "vue";
import VueI18n from "vue-i18n";
import i18n from "../../../i18n";
import vuetify from "../../../plugins/vuetify";
import RestApiService from "@/services/rest.api.service.js";
import "@mdi/font/css/materialdesignicons.css";
import Payment from "./Payment";

Vue.use(VueI18n);

export default {
  components: {
    Payment,
  },
  props: {
    idEvenement: Number,
  },
  data: function () {
    return {
      evenement: {},
      rgForfaits: {},
      choixActivites: [],
      showPayment: false,
      infoAchatArticles: [],
      listeAfficherDescription: [],
    };
  },
  created: function () {
    this.$i18n.locale = this.$locale;
    RestApiService.setTokenHeader(this.$token);
    RestApiService.setLang(this.$locale);
    RestApiService.setServeur(this.$serveur);
    window.scrollTo(0, 0);
  },
  mounted: function () {
    RestApiService.get(
      `/resto/csia/joomla/catalogue/infoInscriptionEvenement/${this.idEvenement}`
    ).then((response) => {
      this.evenement = response.data;
      this.infoAchatArticles = this.evenement.listeArticles.map((a) => ({
        ...a,
        quantite: 0,
      }));
    });
  },
  filters: {
    montant: function (value) {
      return value / 100 + " $";
    },
  },
  methods: {
    removeInAfficherDescription(activite) {
      var index = this.listeAfficherDescription.indexOf(activite.id);
      if (index != -1) {
        this.listeAfficherDescription.splice(index, 1);
      }
    },
    isAfficherDescription(activite) {
      var obj = this.listeAfficherDescription.find((f) => activite.id === f);
      return obj !== undefined;
    },
    onSelectionActivite(activite) {
      var index = this.choixActivites.indexOf(activite);
      if (index !== -1) {
        this.choixActivites.splice(index, 1);
      } else {
        var sameDateindex = -1;
        this.choixActivites.forEach((choix) => {
          if (
            this.getNomActivite(choix.nom) == this.getNomActivite(activite.nom)
          ) {
            sameDateindex = this.choixActivites.indexOf(choix);
            return;
          }
        });
        if (sameDateindex != -1) {
          this.choixActivites.splice(sameDateindex, 1);
        }
        this.choixActivites.push(activite);
      }
    },
    getNomActivite(nom) {
      return nom.substring(nom.lastIndexOf("(") + 1, nom.lastIndexOf(")"));
    },
    addToCart() {
      if (this.choixActivites.length == 0) {
        alert(i18n.t("catalogue.you_must_select_at_least_one_activity"));
        return;
      }
      const articles = this.infoAchatArticles.map((info) => ({
        idArticle: info.id,
        quantite: info.quantite,
        articles: articles,
      }));

      const infoActivites = {
        idForfait: this.rgForfaits.id,
        idsActivites: this.choixActivites.map((c) => c.id),
        articles: articles,
      };
      RestApiService.post(
        `/resto/csia/joomla/panierAchat/ajouter/activites`,
        infoActivites
      ).then(() => {
        this.showPayment = true;
      });
    },
    activiteIsSelected: function (activite) {
      return this.choixActivites.includes(activite);
    },
  },
  computed: {
    listeActivitesDispo: function () {
      const idActivites = this.rgForfaits.activites;

      if (this.evenement && idActivites) {
        return Object.values(this.evenement.activites).filter((a) =>
          idActivites.includes(a.id)
        );
      }

      return [];
    },
  },
  vuetify,
  i18n,
};
</script>

<style scoped>
.btn-wrap-text {
  display: block;
  width: 100%;
  word-break: keep-all;
  text-align: center;
  white-space: normal;
  padding: 8px 16px !important;
  height: 100% !important;
  min-height: 36px;
}

.wrap-evenement-desc {
  margin-bottom: 15px;
}
</style>
