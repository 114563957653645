var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_c('v-avatar',{attrs:{"left":"","size":"64px"}},[_c('v-img',{attrs:{"src":_vm.info.urlImage}})],1),_c('div',{staticClass:"px-3"},[_c('h2',[_vm._v(_vm._s(_vm.info.nom))]),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary-custom","small":""}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.$t("gui.general.details.simple")))])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t("csia.repertoire.detailSeance")))])]),_c('v-card-text',[(_vm.info.description !== '')?_c('h5',[_vm._v(" "+_vm._s(_vm.$t("gui.general.description"))+" ")]):_vm._e(),_c('div',{domProps:{"innerHTML":_vm._s(_vm.info.description)}}),(_vm.info.buts !== '')?_c('h5',[_vm._v(" "+_vm._s(_vm.$t("csia.repertoire.butFormation"))+" ")]):_vm._e(),_c('div',{domProps:{"innerHTML":_vm._s(_vm.info.buts)}}),(_vm.info.préalables !== '')?_c('h5',[_vm._v(" "+_vm._s(_vm.$t("csia.repertoire.prealables"))+" ")]):_vm._e(),_c('div',{domProps:{"innerHTML":_vm._s(_vm.info.préalables)}})]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(_vm._s(_vm.$t("gui.general.fermer")))])],1)],1)],1)],1)],1),_vm._l((_vm.info.listeEmplacements),function(emplacement){return _c('div',{key:emplacement.id},[_c('v-card-subtitle',[_c('h3',{staticClass:"px-3"},[_vm._v(" "+_vm._s(_vm.$t("emplacement.nom"))+" "+_vm._s(emplacement.nom)+" ")])]),_c('v-card-text',[_c('v-data-table',{attrs:{"no-data-text":_vm.$t('gui.general.no_results'),"no-results-text":_vm.$t('gui.general.no_results'),"headers":_vm.headers,"items":emplacement.listeSéances,"items-per-page":999,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.information",fn:function(ref){
var item = ref.item;
return [(item.description)?_c('div',[_c('v-dialog',{attrs:{"max-width":"390"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":"primary-custom"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information-variant")])],1)]}}],null,true),model:{value:(item.dialogDescriotion),callback:function ($$v) {_vm.$set(item, "dialogDescriotion", $$v)},expression:"item.dialogDescriotion"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t('gui.general.description'))+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(item.description))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){item.dialogDescriotion = false}}},[_vm._v(" Ok ")])],1)],1)],1)],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.complet && !item.annulé)?_c('div',[_c('v-btn',{attrs:{"color":"primary-custom"},on:{"click":function($event){return _vm.register(item, _vm.info, emplacement)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-plus")]),_c('span',[_vm._v(_vm._s(_vm.$t("gui.general.register")))])],1)],1):_vm._e(),(item.complet)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('csia.catalogue.complet'))+" "),(_vm.$token)?_c('v-btn',{attrs:{"color":"primary-custom"},on:{"click":function($event){return _vm.ajouterListeAttente(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-plus")]),_c('span',[_vm._v(_vm._s(_vm.$t('csia.catalogue.ajouterListeAttente')))])],1):_vm._e()],1):_vm._e(),(item.annulé)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('csia.catalogue.session-cancelled'))+" ")]):_vm._e()]}}],null,true)})],1)],1)})],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }