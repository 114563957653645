<style>
  .icone-apple {
    width: 150px;
    height: 38px;

  }

  .icone-apple svg {
    width: 150px;
    height: 38px;
  }

  .icone-android {
    width: 150px;
    height: 38px;

  }

  .icone-android svg {
    width: 150px;
    height: 38px;
  }
</style>

<template>
  <v-app>
    <v-skeleton-loader
      v-if="loading"
      v-bind="attrs"
      type="date-picker"
    ></v-skeleton-loader>
    <v-form v-model="profilValide" ref="form" v-show="!loading">
      <v-btn
        style="margin-bottom: 10px"
        class="primary-custom"
        v-if="lienCarteMembre && !nodownload"
        :href="lienCarteMembre"
        target="_blank"
      >{{ btnNomCarteMembre }}</v-btn>
      <!-- <v-btn
        style="margin-bottom: 10px"
        class="primary-custom"
        v-if="lienCarteMembreApple && !nodownload"
        :href="lienCarteMembreApple"
        target="_blank"
      >{{ btnNomCarteMembre }} Apple</v-btn> -->
      <a 
        v-if="lienCarteMembreApple && !nodownload"
        :href="lienCarteMembreApple">
        <v-icon class="icone-apple">{{iconeApple}}</v-icon>
      </a>
      <a 
        v-if="lienCarteMembreAndroid && !nodownload"
        :href="lienCarteMembreAndroid">
        <v-icon class="icone-android">{{iconeAndroid}}</v-icon>
      </a>

      <v-card>
        <v-card-title>{{ $t('profil.renseignement.personnels') }} </v-card-title>
        <v-card-text>
          <v-text-field
            :readonly="true"
            disabled
            :label="$t('profil.editionProfil.tableau.prenom')"
            v-model="profil.prénom"
            :rules="[(valeur) => !!valeur || $t('champ_obligatoire')]"
          ></v-text-field>
          <v-text-field
            :label="$t('profil.editionProfil.tableau.nom')"
            v-model="profil.nom"
            :readonly="true"
            disabled
          ></v-text-field>
          <v-text-field
            :label="$t('profil.editionProfil.tableau.numero')"
            v-model="profil.matricule"
            :readonly="true"
            disabled
            :rules="[(valeur) => !!valeur || $t('champ_obligatoire')]"
          ></v-text-field>
          <v-text-field
            :label="$t('profil.sexe')"
            :value="genre"
            :readonly="true"
            disabled
          ></v-text-field>
          <v-text-field
            :label="$t('profil.dateNaissance')"
            v-model="profil.dateNaissance"
            :readonly="true"
            disabled
          ></v-text-field>
        </v-card-text>
      </v-card>
      <br />
      <v-card>
        <v-card-title>{{ $t("profil.nas") }}</v-card-title>
        <v-card-text>
          <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
         {{ nas.texteNAS }}
        <a
          v-bind="attrs"
          v-on="on"
        >
          {{ nas.texteLienNAS }}
        </a>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">{{nas.texteDialogue}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="nas.nas"
                  required
                  
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="fermerDialogueNAS()"
          >
            {{ $t("gui.general.fermer") }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="sauvegarderNAS()"
          >
          {{ $t("gui.general.sauvegarder") }}
            
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
        </v-card-text>
      </v-card>
      <br />
      <v-card>
        <v-card-title>{{ $t("prefComm.titre") }}</v-card-title>
        <v-card-text>
          <v-text-field
            type="email"
            :label="$t('gui.general.courriel')"
            v-model="profil.courriel"
            :rules="[
              (valeur) => !!valeur || $t('champ_obligatoire'),
              (valeur) =>
                /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(valeur) ||
                $t('gui.general.wrong_email_format'),
            ]"
          ></v-text-field>
          <v-text-field
            :label="$t('gui.general.telephone')"
            v-model="profil.téléphone"
            :rules="[(valeur) => !!valeur || $t('champ_obligatoire')]"
          ></v-text-field>
        </v-card-text>
      </v-card>
      <br />
      <v-card>
        <v-card-title>{{ $t("gui.general.adresse") }}</v-card-title>
        <v-card-text>
          <v-text-field
            :label="$t('lieu.rue')"
            v-model="profil.rue"
            :rules="[(valeur) => !!valeur || $t('champ_obligatoire')]"
          ></v-text-field>
          <v-text-field
            :label="$t('gui.general.casePostale')"
            v-model="profil.casePostale"
          ></v-text-field>
          <v-text-field
            :label="$t('gui.general.aptnumber')"
            v-model="profil.appartement"
          ></v-text-field>
          <v-text-field
            :label="$t('gui.general.ville')"
            v-model="profil.ville"
            :rules="[(valeur) => !!valeur || $t('champ_obligatoire')]"
          ></v-text-field>
          <v-select
            outlined
            :label="$t('gui.general.pays')"
            :items="listePays"
            item-text="libelle"
            item-value="code"
            v-model="profil.codePays"
            @change="onChangePays()"
            :rules="[(valeur) => !!valeur || $t('champ_obligatoire')]"
          ></v-select>
          <v-select
            outlined
            :label="$t('gui.general.province')"
            :items="listeProvinces"
            item-text="libelle"
            item-value="code"
            v-model="profil.codeProvince"
            :rules="[(valeur) => !!valeur || $t('champ_obligatoire')]"
          ></v-select>
          <v-text-field
            :label="$t('gui.general.codePostal')"
            v-model="profil.codePostal"
            :rules="[(valeur) => !!valeur || $t('champ_obligatoire')]"
          >
          </v-text-field>
          <div>
            {{$t('gui.general.langue')}}
            <v-select
              :items="listeLangues"
              v-model="languePortail"
              item-text="libelle"
              item-value="code"
            />
          </div>
        </v-card-text>
      </v-card>
      <br />
      <PreferencesCommunication
        v-if="showPrefCom"
        @updatePrefComInfo="updatePrefComInfo"
      ></PreferencesCommunication>
      <v-btn
        :loading="loadingSave"
        :disabled="loadingSave"
        @click="onSauvegarder"
        class="primary-custom my-4"
      >{{$t('profil.save_profile')}}</v-btn>
      <p class="green--text">{{ saveStatus }}</p>
      <div v-if="!nodownload">
        <Aspirations :title="$t('aspirations.mes_aspirations')"></Aspirations>
      </div>
      <br />

      <Affiliations></Affiliations>
    </v-form>
  </v-app>
</template>

<script>
import Vue from "vue";
import VueI18n from "vue-i18n";
import i18n from "../../i18n";
import vuetify from "../../plugins/vuetify";
import "@mdi/font/css/materialdesignicons.css";
import RestApiService from "@/services/rest.api.service.js";
import PreferencesCommunication from "./PreferencesCommunication.vue";
import Aspirations from "../aspirations/Aspirations";
import Affiliations from './affiliations/Affiliations.vue'

Vue.use(VueI18n);

export default {
  components: {
    PreferencesCommunication,
    Aspirations,
    Affiliations,
  },
  props: {
    showPrefCom: {
      type: Boolean,
      default() {
        return true;
      },
      profilValide : Boolean,
    },
    nodownload: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      profil: {},
      loading: true,
      loadingSave: false,
      listePays: [],
      listeProvinces: [],
      listeLangues: [],
      languePortail: "en",
      saveStatus: "",
      lienCarteMembre: "",
      lienCarteMembreApple: "",
      lienCarteMembreAndroid: "",
      btnNomCarteMembre: "",
      prefComInfo: null,
      nas: {},
      dialog: false,
      iconeApple: null,
      iconeAndroid: null,
      listeGenre: [],
    };
  },
  computed: {
    genre() {
      if (this.profil.genre) {
        const libelle = this.listeGenre.filter((element) => element.clef === this.profil.genre)[0]
        return libelle?.texte ?? "";
      }
      return "";
    },
  },
  created() {
    this.$i18n.locale = this.$locale;
    RestApiService.setTokenHeader(this.$token);
    RestApiService.setLang(this.$locale);
    RestApiService.setServeur(this.$serveur);
    if (this.$locale === 'en') {
      this.iconeApple = '$vuetify.icons.walleten'
      this.iconeAndroid = '$vuetify.icons.walletAndroidEn'
    } else {
      this.iconeApple = '$vuetify.icons.walletfr'
      this.iconeAndroid = '$vuetify.icons.walletAndroidFr'
    }
  },
  mounted() {
    RestApiService.get("/resto/csia/joomla/choixmenu?identifiant=listeGenrePersonne").then(
      (response) => {
        this.listeGenre = response.data;
      }
    );
    const promises = [
      RestApiService.get("/resto/csia/joomla/usager/information").then(
        (response) => {
          this.lienCarteMembre = response.data.lienCarteMembre;
          this.lienCarteMembreApple = response.data.lienCarteMembreApple;
          this.lienCarteMembreAndroid = response.data.lienCarteMembreAndroid;
          this.btnNomCarteMembre = response.data.nomBoutonCarteMembre;
        }
      ),
      RestApiService.get("/resto/csia/joomla/usager/nas").then((response) => {
        this.nas = {...response.data,nas:''}
      }),
      RestApiService.get("/resto/csia/joomla/profil").then((response) => {
        this.profil = response.data;
        this.languePortail = this.profil.langue;
        this.onChangePays();
      }),
      RestApiService.get("/resto/csia/joomla/listeLangue").then((response) => {
        this.listeLangues = response.data;
      }),
      RestApiService.get("/resto/csia/joomla/geo/pays").then((response) => {
        this.listePays = response.data;
      }),
    ];
    
    Promise.all(promises)
      .catch((reason) => {
        console.error(reason);
        alert("One or many requests failed in Profile");
      })
      .finally(() => {
        this.loading = false;
        this.$emit("update:profilValide", this.profilValide);
      });

  },
  methods: {
    updatePrefComInfo: function (prefComInfo) {
      this.prefComInfo = prefComInfo;
    },
    fermerDialogueNAS: function()
    {
      this.nas.nas = '';
      this.dialog = false;
    },
    sauvegarderNAS: function()
    {
      if(this.nas.nasPresent)
      {
        let envoiNas = {nas:this.nas.nas}
        RestApiService.post(
          "/resto/csia/joomla/usager/nas",
          envoiNas
        ).then((response) => {
          if (response.status === 200) {
            this.nas = {...response.data,nas:''};
          }
         
        })
      }
      else{
        let envoiNas = {nas:this.nas.nas}
        RestApiService.put(
          "/resto/csia/joomla/usager/nas",
          envoiNas
        ).then((response) => {
          if (response.status === 200) {
            this.nas = {...response.data,nas:''};
          }
          
        })
      }
      this.fermerDialogueNAS();
    },
     
    onChangePays: function () {
      if (this.profil.codePays){
        RestApiService.get(
          "/resto/csia/joomla/geo/provinces/" + this.profil.codePays || "CA"
        )
          .then((response) => {
            this.listeProvinces = response.data;
          })
          .catch((error) => window.alert(error))
          .finally();
      }
      this.listeProvinces = [];
    },
    onSauvegarder() {
      this.$refs.form.validate();
      this.$emit("update:profilValide", this.profilValide);
      if (!this.profilValide) return;

      this.loadingSave = true;
      let changementLangue = {};

      const promises = [];

      if (this.prefComInfo) {
        promises.push(
          RestApiService.put(
            "/resto/csia/joomla/usager/preferenceCommunication/modifier",
            this.prefComInfo
          )
        );
      }

      changementLangue.langue = this.languePortail;

      promises.push(
        RestApiService.post(
          "/resto/csia/joomla/usager/langue",
          changementLangue
        ).then((response) => {
          if (response.status === 200) {
            this.saveStatus = this.$t("gui.general.sauvegarde");
          } else {
            this.saveStatus = `${this.$t("gui.general.erreur")} ${
              response.status
            }`;
          }
        })
      );

      promises.push(
        RestApiService.put("/resto/csia/joomla/profil", this.profil).then(
          (response) => {
            if (response.status === 200) {
              this.saveStatus = this.$t("gui.general.sauvegarde");
            } else {
              this.saveStatus = `${this.$t("gui.general.erreur")} ${
                response.status
              }`;
            }
          }
        )
      );

      Promise.all(promises).finally(() => {
        this.loadingSave = false;
      });
    },
  },
  vuetify,
  i18n,
};
</script>