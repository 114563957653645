<template>
  <v-app class="wrap-aspirations">
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        <div v-for="aspiration in listeAspirations" :key="aspiration.clef">
          <v-checkbox :label="aspiration.texte" v-model="aspiration.valeur" />
        </div>
      </v-card-text>
      <v-btn
        :loading="loadingSave"
        :disabled="loadingSave"
        style="margin:0 15px;"
        class="primary-custom"
        @click="save"
      >{{ $t('aspirations.save_aspirations') }}</v-btn>
      <p class="green--text asp-result">{{ result }}</p>
    </v-card>
  </v-app>
</template>

<script>
import Vue from "vue";
import VueI18n from "vue-i18n";
import i18n from "../../i18n";
import vuetify from "../../plugins/vuetify";
import RestApiService from "@/services/rest.api.service.js";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(VueI18n);

export default {
  props: {
    title: String
  },
  data: function () {
    return {
      loadingSave: false,
      listeAspirations: [],
      result: "",
    };
  },
  created: function () {
    this.$i18n.locale = this.$locale;
    RestApiService.setTokenHeader(this.$token);
    RestApiService.setLang(this.$locale);
    RestApiService.setServeur(this.$serveur);
  },
  mounted: function () {
    RestApiService.get("/resto/csia/joomla/profil").then((response) => {
      this.listeAspirations = response.data.listeAspirations;
    });
  },
  methods: {
    save: function () {
      this.loadingSave = true;
      const res = {
        aspirationCertification: this.listeAspirations[0].valeur,
        aspirationTeaching: this.listeAspirations[1].valeur,
        aspirationSkiing: this.listeAspirations[2].valeur,
        aspirationBoth: this.listeAspirations[3].valeur,
        aspirationSnowPark: this.listeAspirations[4].valeur,
      };
      RestApiService.put("/resto/csia/joomla/usager/aspirations", res)
        .then(() => {
          this.result = this.$t("gui.general.sauvegarde");
        })
        .catch((error) => {
          this.result = "";
          alert(error);
        })
        .finally(() => {
          this.loadingSave = false;
        });
    },
  },
  computed: {},
  vuetify,
  i18n,
};
</script>

<style scoped>
.asp-result {
  padding-left: 15px;
  padding-top: 10px;
}
</style>

<style>
.wrap-aspirations > .v-application--wrap {
  min-height: auto;
  margin-bottom: 25px;
}
</style>