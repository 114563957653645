<template>
  <div>
    <v-card>
      <v-card-title>{{ $t(titre) }}</v-card-title>
      <v-card-text>
        <div v-for="affiliation in affiliations" :key="affiliation.clef">
          <affiliation :affiliation="affiliation" @sauvegardeAffiliation="chargerInfo()"></affiliation>
        </div>
        <v-btn @click="onAjouterAffiliation">{{$t('affiliation.add-a-new-teaching-location')}}</v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import RestApiService from "@/services/rest.api.service.js";
import Affiliation from "./Affiliation.vue";


export default {
  props: {
    titre: {
      type: String,
      default: "affiliation.teaching-locations"
    }
  },
  data() {
    return {
      affiliations: [],
    };
  },
  components: {
    Affiliation,
  },
  methods: {
    onAjouterAffiliation() {
      this.affiliations.push({
          nouveau: true,
          titreEmploi: '',
      });
    },
    chargerInfo() {
      this.affiliations.length = 0;
      RestApiService.get("/resto/csia/joomla/usager/affiliations")
        .then((response) => {
          for (let aff of response.data) {
            this.affiliations.push(aff);
          }
          this.$forceUpdate();
        })
        .catch((error) => window.alert(error));
    },
  },
  mounted() {
    this.chargerInfo();
  },
  created() {},
};
</script>