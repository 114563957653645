<template>
  <div>
    <b-button
      @click="openModal()"
      class="btn-bold btn-label-brand custom-btn-recherche"
    >
      {{ titre }}
      <span v-if="choix.length > 0">({{ choix.length }})&nbsp;</span>
      <b-icon icon="plus-square-fill" aria-hidden="true"></b-icon>
    </b-button>
    <br />
    <v-chip
      v-for="item in nomsSelected"
      :key="item.id"
      small
      close
      @click:close="onClose(item)"
      >{{ item.libellé }}</v-chip
    >
    <b-modal
      :id="'modal-' + titre"
      :title="titre"
      :cancel-title="$t('gui.general.annuler')"
    >
      <b-form-input
        autofocus
        v-model="mots"
        :placeholder="$t('gui.general.recherche')"
      />
      <v-card outlined class="mt-1">
        <v-card-text>
          <div style="max-height: 300px; overflow: scroll">
            <div v-for="item of filteredItems" :key="item.id">
              <b-form-checkbox
                v-model="choix"
                :value="item.id"
                @input="$emit('update:choix', choix)"
                >{{ item.libellé }}</b-form-checkbox
              >
            </div>
          </div>
        </v-card-text>
      </v-card>
    </b-modal>
  </div>
</template>

<style>
.modal-header button.close {
  display: none;
}
</style>


<script>
export default {
  props: {
    titre: String,
    choix: Array,
    items: Array,
  },

  data() {
    return {
      mots: "",
      selected: [],
    };
  },
  mounted() {},
  methods: {
    openModal: function () {
      this.$bvModal.show("modal-" + this.titre);
    },
    onClose: function (tag) {
      this.choix = this.choix.filter((e) => e !== tag.id);
      this.$emit("update:choix", this.choix);
    },
  },
  computed: {
    filteredItems: function () {
      if (this.items != null) {
        let f = this.items;
        if (f !== undefined) {
          f = f.filter((i) => {
            return i.libellé.toLowerCase().includes(this.mots.toLowerCase());
          });
          return f;
        }
      }
      return [];
    },
    nomsSelected: function () {
      if (this.items == null) {
        return [];
      } else {
        let sel = this.choix;
        return this.items.filter((n) => sel.includes(n.id));
      }
    },
  },
};
</script>
<style scoped>
.custom-btn-recherche {
  color: #dc291e !important;
  background-color: rgba(220, 41, 30, 0.1) !important;
}
.custom-btn-recherche:hover {
  color: #ffffff !important;
  background-color: #dc291e !important;
}
</style>