import axios from "axios";

let proxyUrl = new URL(window.location)
// proxyUrl.pathname = "/vue/php/jooproxy.php"
let proxy = proxyUrl.origin + '/vue/php/jooproxy.php';

const utiliserProxy = true;

var langue = "fr"

var urlServeur = "z";

const RestApiService = {

  init() {
    console.log("init!");
  },

  setTokenHeader(token) {
    axios.defaults.headers.common[
      "Authorization"
    ] = 'Token ' + token;
  },

  setServeur(s) {
    urlServeur = s;
    //urlServeur = "http://localhost:8080/Acrobatik"; 
  },

  setLang(lang) {
    langue = lang;
  },

  async get(resource) {
    let url = urlServeur + resource;

    if (utiliserProxy) {
      url = proxy + "?url=" + urlServeur + resource;
    }

    return axios.get(url, {
      headers: { 'accept-language': langue }
    }).catch(error => {
      redirectToLoginCheck(error);
      throw new Error(`RestApiService ${error} : ${url}`);
    });
  },

  async getOctetStream(resource) {
    let url = urlServeur + resource;

    if (utiliserProxy) {
      url = proxy + "?url=" + urlServeur + resource;
    }

    return axios.get(url, {
      headers: { 'accept-language': langue },
      responseType: 'arraybuffer'
    }).catch(error => {
      redirectToLoginCheck(error);
      throw new Error(`RestApiService ${error} : ${url}`);
    });
  },

  async post(resource, params) {
    let url = urlServeur + resource;
    if (utiliserProxy) {
      url = proxy + "?url=" + urlServeur + resource;
    }

    return axios.post(url, params, {
      headers: { 'accept-language': langue }
    }).catch(error => {
      redirectToLoginCheck(error);
      throw new Error(`RestApiService ${error} : ${url}`);
    });
  },

  async postFile(resource, formData) {
    let url = urlServeur + resource;
    if (utiliserProxy) {
      url = proxy + "?url=" + urlServeur + resource;
    }

    return axios.post(url, formData, {
      headers: { 'accept-language': langue, 'Content-Type': 'multipart/form-data' }
    }).catch(error => {
      redirectToLoginCheck(error);
      throw new Error(`RestApiService ${error} : ${url}`);
    });
  },

  async postCustomError(resource, params) {
    let url = urlServeur + resource;
    if (utiliserProxy) {
      url = proxy + "?url=" + urlServeur + resource;
    }

    return axios.post(url, params).catch(error => {
      redirectToLoginCheck(error)
    });
  },

  async put(resource, params) {
    let url = urlServeur + resource;
    if (utiliserProxy) {
      url = proxy + "?url=" + urlServeur + resource;
    }

    return axios.put(
      url,
      params,
      {
        headers: { 'accept-language': langue }
      }
    ).catch(error => {
      redirectToLoginCheck(error);
      throw new Error(`RestApiService ${error} : ${url}`);
    });
  },

  async delete(resource) {
    let url = urlServeur + resource;

    if (utiliserProxy) {
      url = proxy + "?url=" + urlServeur + resource;
    }

    return axios.delete(url, {
      headers: { 'accept-language': langue }
    }).catch(error => {
      throw new Error(`RestApiService ${error} : ${url}`);
    });
  },
}

function redirectToLoginCheck(error) {
  if (error.response.status == '418') {
    const array = window.location.pathname.split("/");
    const lang = array[1]; // en || fr
    const result = `${lang}/component/users/?view=login&Itemid=666`;
    window.location.pathname = result;
  }
  else {
    return error;
  }
}

export default RestApiService;
