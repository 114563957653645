<template>
    <div v-if="!loading">
      <div v-if="tropTard">
        <v-card>
          <v-card-text>
            <p class="text-body-1">
              {{$t('seance-perimee')}}
            </p>
          </v-card-text>
          <v-card-actions>
             <v-btn
            style="width: 100%"
            class="btn-wrap-text primary-custom"
            @click="$emit('quitNewAccount')"
            >{{ $t("register.return_to_training_directory") }}</v-btn
          >
          </v-card-actions>
        </v-card>
      </div>
      <div v-if="!tropTard">
        <v-card
          v-if="state === 'initial' || state === 'aucun'"
          class="v-card"
          style="margin-bottom: 10px"
        >
          <h3>{{ $t("new_account.already_have_account") }}</h3>
          <v-btn @click="redirectToLogin()" class="btn primary-custom">{{
            $t("gui.general.login")
          }}</v-btn>
        </v-card>
        <v-card class="v-card" v-if="seanceInfo.porteEntrée">
          <div v-if="state === 'initial' || state === 'aucun'">
            <h3>{{ $t("new_account.create_new_account") }}</h3>
            <v-form ref="form" v-model="validationFormValid">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="account.firstName"
                    :label="$t('gui.general.first_name')"
                    :rules="[(v) => !!v || $t('champ_obligatoire')]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="account.lastName"
                    :label="$t('gui.general.last_name')"
                    :rules="[(v) => !!v || $t('champ_obligatoire')]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          :label="$t('gui.general.date_of_birth')"
                          :rules="[(v) => !!v || $t('champ_obligatoire')]"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date"
                        :active-picker.sync="activePicker"
                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)"
                        min="1900-01-01"
                        @change="saveDateOfBirth"
                      ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" sm="6">
                  <div class="d-flex" style="padding-top: 20px">
                    <span style="margin-right: 15px"
                      >{{ $t("gui.general.gender") }}:
                    </span>
                    <v-radio-group
                      v-model="gender"
                      row
                      class="gender-radio-group"
                    >
                      <v-radio
                        class="gender-radio"
                        :label="$t('gui.general.man')"
                        :value="true"
                      ></v-radio>
                      <v-radio
                        class="gender-radio"
                        :label="$t('gui.general.woman')"
                        :value="false"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    type="email"
                    v-model="account.email"
                    :rules="[
                      (v) => !!v || $t('champ_obligatoire'),
                      (v) =>
                        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                          v
                        ) || $t('gui.general.wrong_email_format'),
                    ]"
                    :label="$t('gui.general.email')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    type="email"
                    v-model="emailConfirmation"
                    :label="$t('gui.general.email_confirmation')"
                    :rules="[
                      (v) => !!v || $t('champ_obligatoire'),
                      (v) =>
                        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                          v
                        ) || $t('gui.general.wrong_email_format'),
                    ]"
                  ></v-text-field>
                </v-col>
                <div style="color: red; margin: 10px" v-if="emailWarning">
                  {{ emailWarning }}
                </div>
              </v-row>
              <v-row>
                <v-col cols="12 text-center">
                  <v-btn
                    :loading="loadingValidate"
                    :disabled="loadingValidate"
                    @click="validate()"
                    class="primary-custom"
                    >{{ $t("gui.general.validate") }}</v-btn
                  >
                </v-col>
              </v-row>
              <div v-if="state === 'aucun'" style="padding-top: 25px">
                <p v-html="encarts.youWillReceive" class="text-center"></p>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      type="password"
                      v-model="account.password"
                      :label="$t('gui.general.password')"
                      :rules="[(v) => !!v || $t('champ_obligatoire')]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field class="text-body-1"
                      type="password"
                      v-model="passwordConfirmation"
                      :label="$t('gui.general.password_confirmation')"
                      :rules="[(v) => !!v || $t('champ_obligatoire')]"
                    ></v-text-field>
                  </v-col>
                  <div style="color: red; margin: 10px" v-if="passwordWarning">
                    {{ passwordWarning }}
                  </div>
                </v-row>
                <v-card-title>{{
                  $t("profil.preferences_communications")
                }}</v-card-title>
                <v-row>
                  <v-col cols="12" sm="8">
                    <v-card-text
                      class="pref-com-encart"
                      v-html="encarts.communicationOrdre"
                    ></v-card-text>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    class="d-flex justify-center align-center"
                  >
                    <span class="radio-no">{{ $t("gui.general.no") }}</span>
                    <v-switch v-model="recevoirPromotion"></v-switch>
                    <span class="radio-yes">{{ $t("gui.general.yes") }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="8">
                    <v-card-text
                      class="pref-com-encart"
                      v-html="encarts.communicationPartenaires"
                    ></v-card-text>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    class="d-flex justify-center align-center"
                  >
                    <span class="radio-no">{{ $t("gui.general.no") }}</span>
                    <v-switch
                      hide-details
                      v-model="recevoirPromotionTiers"
                    ></v-switch>
                    <span class="radio-yes">{{ $t("gui.general.yes") }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12 text-center">
                    <v-btn
                      :loading="loadingCreate"
                      :disabled="loadingCreate"
                      @click="createAccount()"
                      class="primary-custom"
                      >{{ $t("account.create_new_account") }}</v-btn
                    >
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </div>
          <div v-else>
            <p v-if="state === 'multiple'" v-html="encarts.manyAccount"></p>
            <div v-else-if="state === 'success'">
              <p v-html="encarts.createSuccess"></p>
              <p>
                <strong>{{ $t("chem.membership-number") }} </strong> :
                {{ nomUsager }}
              </p>
              <p>
                <v-btn @click="goToLogin()" class="btn primary-custom">{{
                  $t("gui.general.login")
                }}</v-btn>
              </p>
            </div>
          </div>
        </v-card>
      </div>
    </div>
</template>

<script>
import Vue from "vue";
import VueI18n from "vue-i18n";
import i18n from "../../../i18n";
import vuetify from "../../../plugins/vuetify";
import RestApiService from "@/services/rest.api.service.js";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(VueI18n);

export default {
  props: {
    seance: Object,
  },
  data: () =>({
    loading: true,
      account: {},
      menu: false,
      gender: true,
      emailConfirmation: "",
      passwordConfirmation: "",
      validationFormValid: false,
      state: "initial",
      encarts: {
        communicationOrdre: "",
        communicationPartenaires: "",
        createSuccess: "",
        manyAccount: "",
        youWillReceive: "",
      },
      emailWarning: "",
      passwordWarning: "",
      loadingValidate: false,
      loadingCreate: false,
      recevoirPromotion: false,
      recevoirPromotionTiers: false,
      nomUsager: "",
      seanceInfo: {},
      tropTard: false,
      date:null,
      activePicker: null
  }),
  watch: {
    menu(val) {        
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    }
  },
  created: function () {
    this.$i18n.locale = this.$locale;
    RestApiService.setTokenHeader(this.$token);
    RestApiService.setLang(this.$locale);
    RestApiService.setServeur(this.$serveur);
  },
  mounted: function () {
    const encartUrl = `/resto/csia/joomla/util/encart/${this.$locale}`;

    const promises = [
      RestApiService.get(
        `/resto/csia/joomla/catalogue/infoActiviteSeance/${this.seance.type}/${this.seance.identifiant}`
      ).then((response) => {
        this.seanceInfo = response.data;
        const now = new Date();
        // const dateDébutInscription = new Date(this.seanceInfo.dateDebutInscription)
        const dateFinInscription = new Date(this.seanceInfo.dateFinInscription);
        if (now > dateFinInscription) {
          this.tropTard = true;
        }
      }),
      RestApiService.get(`${encartUrl}/.prefComm.CommunicationOrdre`).then(
        (response) => (this.encarts.communicationOrdre = response.data)
      ),
      RestApiService.get(
        `${encartUrl}/.prefComm.CommunicationPartenaires`
      ).then(
        (response) => (this.encarts.communicationPartenaires = response.data)
      ),
      RestApiService.get(
        `${encartUrl}/.inscription.creationcompte.succes`
      ).then((response) => (this.encarts.createSuccess = response.data)),
      RestApiService.get(
        `${encartUrl}/.inscription.multiplecomptes.information`
      ).then((response) => (this.encarts.manyAccount = response.data)),
      RestApiService.get(
        `${encartUrl}/.inscription.nomusager.information`
      ).then((response) => (this.encarts.youWillReceive = response.data)),
    ];

    Promise.all(promises).then(()=>(this.loading = false))
    
  },
  methods: {
    saveDateOfBirth (date) {
        this.$refs.menu.save(date)
        this.account.dateOfBirth = date
      },
    redirectToLogin: function () {
      // https://docs.joomla.org/How_do_you_redirect_users_after_a_successful_login%3F
      let urlRepertoire = "";
      if (this.$i18n.locale === "fr") {
        urlRepertoire =
          "index.php?option=com_content&view=article&Itemid=187&lang=fr";
      } else {
        urlRepertoire =
          "index.php?option=com_content&view=article&Itemid=142&lang=en";
      }
      const urlDest = `${urlRepertoire}&type=${this.seance.type}&identifiant=${this.seance.identifiant}`;
      const urlDest64 = btoa(urlDest);
      const urlConnexion = "/component/users/?view=login&Itemid=102";
      const urlConnexionRedirect = urlConnexion + "&return=" + urlDest64;
      window.location = urlConnexionRedirect;
      /*
      const array = window.location.pathname.split("/");
      const lang = array[1]; // en || fr
      const result = `${lang}/component/users/?view=login&Itemid=666`;
      window.location.pathname = result;
      */
    },
    goToLogin: function () {
      const url = window.location.pathname;
      // const origin = window.origin;
      // // autologin va remplacer le caractère _ par &
      // const path = `${origin}/vue/php/autologin.php?username=${this.nomUsager}&password=${this.account.password}&url=${url}?type=${this.seance.type}_identifiant=${this.seance.identifiant}`;
      // window.location = path;

      const redirectUrl = `${url}?type=${this.seance.type}&identifiant=${this.seance.identifiant}`;

      const data = {
        username: this.nomUsager,
        password: this.account.password
      };

      fetch('/index.php?option=com_ajax&plugin=cnxajaxlogin&group=system&format=json', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'same-origin'
      })
          .then(response => response.json())
          .then(data => {
            if (data.success) {
              // User logged in successfully
              console.log('Login successful');
              window.location = redirectUrl;
            } else {
              // Handle error
              console.log('Login failed: ' + data.message);
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          });
    },
    validate: function () {
      this.emailWarning = "";
      this.passwordWarning = "";
      this.$refs.form.validate();

      if (
        this.account.email &&
        this.emailConfirmation &&
        this.account.email !== this.emailConfirmation
      ) {
        this.emailWarning = this.$t("gui.general.emails_must_be_same");
        return;
      }

      this.loadingValidate = true;

      if (this.validationFormValid) {
        const body = {
          prenom: this.account.firstName,
          nom: this.account.lastName,
          dateNaissance: this.account.dateOfBirth,
          sexe: this.gender,
          courriel: this.account.email,
          motDePasse: "",
          prefComm: null,
          prefCommTiers: null,
          langue: "",
        };
        RestApiService.put(`/resto/csia/joomla/creationCompte/validation`, body)
          .then((response) => {
            const data = response.data;
            if (data.etat === "unique") {
              //              const array = window.location.pathname.split("/");
              //              const lang = array[1]; // en || fr
              //              const result = `${lang}/component/users/?view=login&Itemid=666`;
              //              window.location.pathname = result;
            } else {
              this.state = data.etat;
            }
          })
          .finally(() => (this.loadingValidate = false));
      } else {
        this.loadingValidate = false;
      }
    },
    createAccount: function () {
      this.emailWarning = "";
      this.passwordWarning = "";
      this.$refs.form.validate();

      if (
        this.account.email &&
        this.emailConfirmation &&
        this.account.email !== this.emailConfirmation
      ) {
        this.emailWarning = this.$t("gui.general.emails_must_be_same");
        return;
      }

      if (
        this.account.password &&
        this.passwordConfirmation &&
        this.account.password !== this.passwordConfirmation
      ) {
        this.passwordWarning = this.$t("gui.general.passwords_must_be_same");
        return;
      }

      this.loadingCreate = true;

      if (this.validationFormValid) {
        window.scrollTo(0, 0);
        const body = {
          prenom: this.account.firstName,
          nom: this.account.lastName,
          dateNaissance: this.account.dateOfBirth,
          sexe: this.gender,
          courriel: this.account.email,
          motDePasse: this.account.password,
          prefComm: this.recevoirPromotion,
          prefCommTiers: this.recevoirPromotionTiers,
          langue: this.$locale,
        };
        RestApiService.put(`/resto/csia/joomla/creationCompte/creation`, body)
          .then((res) => {
            this.state = "success";
            this.nomUsager = res.data.nomUsager;
          })
          .finally(() => (this.loadingCreate = false));
      } else {
        this.loadingCreate = false;
      }
    },
  },
  computed: {},
  vuetify,
  i18n,
};
</script>

<style scoped>
.v-card {
  padding: 25px;
}

.gender-radio-group {
  margin: 0;
  padding: 0;
}

.radio-no {
  padding-top: 12px;
  padding-right: 20px;
}

.pref-com-encart {
  font-size: 1rem;
}

.radio-yes {
  padding-top: 12px;
  padding-left: 5px;
}
</style>

<style>
.gender-radio label {
  margin-bottom: 0;
}
</style>